import ContactPhone from "assets/images/contactUs/contactPhone.svg";
import ContactMail from "assets/images/contactUs/contactMail.svg";
import ContactInquiry from "assets/images/contactUs/contactInquiry.svg";
import FadeUp from "components/FadeUp/index";

const Enquiry = () => {
  return (
    <>
      <section className="enquiry">
        <div className="container">
          <FadeUp>
            <div className="enquiryCards">
              <div className="enquiryIcon">
                <img src={ContactInquiry} alt="contactInquiry" />
                <div>
                  <span className="enquiryHeading">General inquiry</span>
                  <p>
                    Reach out to us with your general enquiry and we&apos;ll
                    provide prompt assistance
                  </p>
                </div>
              </div>
              <div className="enquiryContact">
                <div>
                  <img src={ContactPhone} alt="contactPhone" />
                  <span>+91-7892721984</span>
                </div>
                <div>
                  <img src={ContactMail} alt="contactMail" />
                  <span>info@bharatmandi.com</span>
                </div>
              </div>
            </div>
          </FadeUp>
        </div>
      </section>
    </>
  );
};

export default Enquiry;
