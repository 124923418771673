import Experience2021 from "assets/images/aboutUs/experience2021.png";
import Experience2022 from "assets/images/aboutUs/experience2022.png";
import Experience2023 from "assets/images/aboutUs/experience2023.png";

import ExperienceLine from "assets/images/aboutUs/experienceLine.png";

import ExperienceImage1 from "assets/images/aboutUs/experienceImage1.png";
import ExperienceImage2 from "assets/images/aboutUs/experienceImage2.png";
import ExperienceImage3 from "assets/images/aboutUs/experienceImage3.png";

import FadeUp from "components/FadeUp/index";

const Experiences = () => {
  return (
    <section className="common experience">
      <div className="container">
        <div className="commonHeading">
          <FadeUp>
            <h2>The experiences we have traversed</h2>
          </FadeUp>
        </div>

        <div className="experienceContainer">
          <div className="experienceDate">
            <div className="experienceDateOne">
              <img src={Experience2021} alt="Experience2021" />
            </div>
            <div className="experienceDateTwo">
              <img src={Experience2022} alt="Experience2022" />
            </div>
            <div className="experienceDateThree">
              <img src={Experience2023} alt="Experience2023" />
            </div>
          </div>

          <div className="experienceLine">
            <img src={ExperienceLine} alt="ExperienceLine" />
          </div>

          <div className="experienceSection">
            <div className="experienceDateMobile">
              <img src={Experience2021} alt="Experience2021" />
            </div>
            <FadeUp>
              <div className="experienceWrapper">
                <div className="experienceImage">
                  <img src={ExperienceImage1} alt="ExperienceImage1" />
                </div>
                <div className="experienceContents">
                  <span>Sept</span>
                  <p>Founding team came together.</p>
                </div>
                <div className="experienceContents">
                  <span>Nov 25</span>
                  <p>Bharat Mandi Incorporated.</p>
                </div>
              </div>
            </FadeUp>

            <div className="experienceDateMobile">
              <img src={Experience2022} alt="Experience2022" />
            </div>
            <FadeUp>
              <div className="experienceWrapper">
                <div className="experienceImage">
                  <img src={ExperienceImage2} alt="ExperienceImage2" />
                </div>

                <div className="experienceContents">
                  <span>Mar 31</span>
                  <p>Gram Paathshala Incorporated.</p>
                </div>

                <div className="experienceContents">
                  <span>Apr 15</span>
                  <p>
                    Phase 1 pilot launched in 4 districts, 8 talukas of Eastern
                    Dry Zone of Karnataka
                  </p>
                </div>

                <div className="experienceContents">
                  <span>Apr 20</span>
                  <p>Loan Mandi Incorporated.</p>
                </div>
                <div className="experienceContents">
                  <span>Jun 1</span>
                  <p>
                    MoU signed with University of Agricultural Sciences
                    Bangalore, GKVK for knowledge transfer.
                  </p>
                </div>
                <div className="experienceContents">
                  <span>Jul 31</span>
                  <p>Bharat Mandi completes Founders’ Capital raise</p>
                </div>
                <div className="experienceContents">
                  <span>Nov 1</span>
                  <p>
                    End of Phase-1 pilot. All package of practices for 20 crops
                    validated on-field.
                  </p>
                </div>
              </div>
            </FadeUp>

            <div className="experienceDateMobile">
              <img src={Experience2023} alt="Experience2023" />
            </div>
            <FadeUp>
              <div className="experienceImage">
                <img src={ExperienceImage3} alt="ExperienceImage3" />
              </div>
            </FadeUp>

            <div className="experienceContents">
              <span>Jan 15</span>
              <p>
                Finalization of 24 horticulture crop patterns and Gram
                Paathshala&apos;s Package of Practices for Phase 2.
              </p>
            </div>

            <div className="experienceContents">
              <span>Feb 1</span>
              <p>
                We onboarded the founding team of Statlogic, a livestock-tech
                company to foray into livestock vertical.
              </p>
            </div>
            <div className="experienceContents">
              <span>Feb 20</span>
              <p>
                We acquired Infarmsys, a soil testing company. Founders Ram &
                Prakash join Bharat Mandi team.
              </p>
            </div>
            <div className="experienceContents">
              <span>Mar 15</span>
              <p>
                40 Raitha Mitras on-boarded across 6 talukas of Kolar district
                in Karnataka
              </p>
            </div>
            <div className="experienceContents">
              <span>Mar 15</span>
              <p>Bengaluru Head Office Established</p>
            </div>
            <div className="experienceContents">
              <span>Apr 15</span>
              <p>
                We signed MoU with Uzhavaralayam Farmer Producer Company, Vanur
                taluka, Villupuram district, Tamil Nadu for significantly
                enhancing the livelihood of women dairy producers by promoting
                residue-free milk production
              </p>
            </div>
            <div className="experienceContents">
              <span>May 15</span>
              <p>
                Phase-2 launch with 12 Kharif Season Horticulture crops in 2
                districts, 9 talukas of Eastern Dry Zone, Karnataka - to cover
                11,000 farmers by Jan 2024
              </p>
            </div>

            <div className="experienceContents">
              <span>May 20</span>
              <p>
                We partnered with leading retail chains in Chennai and Bangalore
                to sell milk and vegetables directly from farmers to consumers
                eliminating middlemen
              </p>
            </div>
            <div className="experienceContents">
              <span>May 23</span>
              <p>
                Gram Paathshala conducted baseline survey of 800 smallholder and
                landless dairy farmers of Vanur taluk,
                Villupuram district, Tamil Nadu along with Uzhavaralayam FPC.
              </p>
            </div>
            <div className="experienceContents">
              <span>July 14</span>
              <p>
                We launched country’s first Residue-free Milk Production program
                with Uzhavaralayam FPC in Tamil Nadu with inauguration of Tamil
                Nadu’s first village level milk collection centres equipped with
                micro can-chillers
              </p>
            </div>
            <div className="experienceContents">
              <span>July 15</span>
              <p>
                We started milk procurement from smallholder women dairy farmers
                and offer fair and transparent pricing based on milk quality.
              </p>
            </div>
            <div className="experienceContents">
              <span>Aug 1</span>
              <p>
                We partnered with Indian Institute of Technology Madras to
                develop and commercialise low-cost devices for rapid detection
                of multiple adulterants in liquid foods
              </p>
            </div>
            <div className="experienceContents">
              <span>Aug 15</span>
              <p>
                We launched Bharat Mandi Farmer Field Schools - a platform
                tailored exclusively for educating small women dairy farmers on
                best farming practices. These insightful sessions are
                thoughtfully scheduled during late hours, tailored to the
                demanding routines of these dedicated farmers.
              </p>
            </div>
            <div className="experienceContents">
              <span>Sept 1</span>
              <p>
                Onboarded Mr. Pankaj Kumar Sharma to our advisory board. With
                over 36 years of experience in the dairy sector, Mr. Pankaj has
                held various roles at Mother Dairy and NDDB. He now provides
                valuable guidance on matters related to enterprise risk
                management, cost optimisation, and financial planning.
              </p>
            </div>
            <div className="experienceContents">
              <span>Sept 10</span>
              <p>
                We signed MoU with The University of Trans-Disciplinary Health
                Sciences and Technology (TDU) Bangalore for promoting use of
                ethno veterinary medicines in dairy farming.
              </p>
            </div>
          </div>
          {/* </FadeUp> */}
        </div>
      </div>
    </section>
  );
};

export default Experiences;
