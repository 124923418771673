import FounderImage1 from "assets/images/aboutUs/founderImage1.png";
import FounderImage2 from "assets/images/aboutUs/founderImage2.png";
import FounderImage3 from "assets/images/aboutUs/founderImage3.png";
import FounderImage4 from "assets/images/aboutUs/founderImage4.png";
import FounderImage5 from "assets/images/aboutUs/founderImage5.png";
import FounderImage6 from "assets/images/aboutUs/founderImage6.png";

export const founders = [
  {
    id: 1,
    name: "Kumar Subramanian",
    title: "Executive Chairman & Managing Director",
    image: FounderImage1,
    description:
      "Serial Entrepreneur and banker with more than 35 years of experience, Kumar has worked with Bank of America, Standard Chartered, and was a founding team member of HDFC Bank. Having set up a pioneering KPO that served the world’s leading banks in the North America and Europe, he is now a social capitalist, angel investor, and mentor in more than a dozen startups fostering entrepreneurship in India",
    profile: "https://www.linkedin.com/in/kumarsubramanian/"
  },
  {
    id: 2,
    name: "Vikram Jetley",
    title: "Chief Executive Officer",
    image: FounderImage2,
    description:
      "Serial entrepreneur and Retail banker with 25+ yrs experience of having worked with top Indian Private Sector Banks and International Banks overseas. He is instrumental in setting up BOP/Small ticket Cluster-based MSME lending business in India",
    profile: "https://www.linkedin.com/in/vikram-jetley-25640127b/"
  },
  {
    id: 3,
    name: "Ganesh K",
    title: "Joint Chief Executive Officer",
    image: FounderImage3,
    description:
      "Retail Banker with leadership experience in India & Middle East across multiple business verticals including Marketing & Analytics. Global Solutions leader providing business transformation solutions to international Banking & Financial Services clients.",
    profile: "https://www.linkedin.com/in/ganesh-krishnamoorthy-ab9606/"
  },
  {
    id: 4,
    name: "Bala Murali",
    title: "Chief Business Officer",
    image: FounderImage4,
    description:
      "A Banker with more than three decades of work experience, Bala has worked with ANZ Grindlays Bank, IDBI Bank, DCB Bank and HDFC Bank. He then set up the business in South India for Aye Finance, a start up NBFC then and scaled it up. He now heads Business Development and Partnerships at Bharat Mandi.",
    profile: "https://www.linkedin.com/in/bala-murali-b-n-116aa035/"
  },
  {
    id: 5,
    name: "Pramesh Khanna",
    title: "Chief Operating Officer",
    image: FounderImage5,
    description:
      "A passionate Agriculturist, Rural Agripreneur and banker with over 20 years of experience, Pramesh has worked with Parke Davis Pfizer, IDBI Bank, YES Bank and Religare. He is also the Founder of 3 bootstrapped rural enterprises in the Hospitality, Adventure, Organics and Fusion Food sectors. He is now a full time Rural Entrepreneurship mentor and actively engaged in Agricultural practices, while furthering his Plant Sciences domain experience into Lab to Land and Farm to Fork products and services.",
    profile: "https://www.linkedin.com/in/pkhanna2008/"
  },
  {
    id: 6,
    name: "Sivakumar Krishnan",
    title: "Chief Information & Technology Officer",
    image: FounderImage6,
    description:
      "A techno-functional banker with more than 3 decades of experience, Siva has worked in PNB, SCB, IDBI Bank, HDFC Bank before moving on to the MFI/NBFC space. He has overseen technology & operations at BASIX, IFMR Rural Channels, Samunnati, MPower Microfinance and has also worked in FIS Global. Siva has done projects for UNDP and NABARD and also is experienced in auditing CBS.",
    profile: "https://www.linkedin.com/in/sivakumar-krishnan-1285437/"
  }
];
