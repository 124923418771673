import { useEffect, useRef, useState } from "react";

import { dairyFarmers } from "constant/dairyFarmers";
import FadeUp from "components/FadeUp/index";

// styles
import "containers/AgriFarm/agriFarm.scss";

const DairyFarmGrowth = () => {
  const [counter, setCounter] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    let interval;
    const handleScroll = () => {
      if (ref.current.getBoundingClientRect()?.top < 350) {
        interval = setInterval(() => {
          setCounter((prevCount) => prevCount + 1);
        }, 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //  function
  const counterDisplay = (index) => {
    switch (index) {
      case 0:
        return counter > 1500 ? "1500+" : counter;
      case 1:
        return counter > 2000 ? "2000+" : counter;
      case 2:
        return counter > 2 ? "2+" : counter;
      case 3:
        return counter > 20 ? "20+" : counter;
      default:
        return counter;
    }
  };

  return (
    <section className="dairyGrowth" ref={ref}>
      <div className="container">
        <FadeUp>
          <h2 className="commonHeading">
            Bharat Mandi&apos;s Impact Metrics for Dairy Sector
          </h2>

          <div className="dairyGrowthCardsWrap">
            {dairyFarmers.map((dataItem, index) => (
              <div className="dairyGrowthCard" key={index}>
                <span>{counterDisplay(index)}</span>
                <p>{dataItem?.description}</p>
              </div>
            ))}
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default DairyFarmGrowth;
