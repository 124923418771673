export const dairyFarmers = [
  {
    value: "1500+",
    description: "Women Dairy Farmers Enrolled"
  },
  {
    value: "2000+",
    description: "Dairy Cattle Onboarded on Pashu Aadhaar platform"
  },
  {
    value: "2+",
    description: "Dairy and Fodder FPCs created"
  },
  {
    value: "20+",
    description: "Banks Working with us for offering loans for farmers"
  }
];
