import Banner from "containers/Products/Banner";
import OurProducts from "containers/Products/OurProducts";

import bgImage from "assets/images/products/product-banner.png";
import smbgImage from "assets/images/products/product-banner.png";

const index = () => {
  const title = "Transforming agriculture for a sustainable tomorrow, our solutions prioritize eco-friendly practices, ensuring profitability and enhancing livelihoods";
    return (
      <>
        <Banner title={title} bgImage={bgImage} smBgImage={smbgImage} />
        <OurProducts />
      </>
    );
  };
  
  export default index;