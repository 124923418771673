import Terms from "containers/Terms/Terms";

const index = () => {
  return (
    <>
      <Terms />
    </>
  );
};

export default index;
