import Banner from "containers/AboutUs/Banner";
import Challenges from "containers/AboutUs/Challenges";
import Team from "containers/AboutUs/Team";
import Brands from "containers/AboutUs/Brands";
import Experiences from "containers/AboutUs/Experiences";

import bgImage from "assets/images/aboutUs/bannerImage.png";
import smbgImage from "assets/images/aboutUs/bannerResponsive.png";

const AboutUs = () => {
  const title =
    "We are a team of seasoned ex-bankers, tech entrepreneurs and agricultural professionals who have come together to solve the problems faced by 12.5+ crores small and marginal farmers";
  return (
    <>
      <Banner title={title} bgImage={bgImage} smBgImage={smbgImage} />
      <Challenges />
      <Experiences />
      <Team />
      <Brands />
    </>
  );
};

export default AboutUs;
