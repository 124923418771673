import FadeUp from "components/FadeUp/index";
import { farmers } from "constant/empowerCard";

const AgriEmpower = () => {
  return (
    <FadeUp>
      <section className="agriEmpower">
        <div className="container">
          <h2 className="agriGrowthHeading">
            How We Empower Farmers Through Our Holistic Approach
          </h2>
          {farmers.map((farmer) => (
            <div className="agriEmpowerApproach" key={farmer.id}>
              <div className="agriEmpowerImage">
                <img src={farmer?.image} alt={farmer.name} />
              </div>

              <div className="agriEmpowerCardName">
                <h3>{farmer?.name}</h3>

                <div>
                  <p>{farmer?.description}</p>
                  <span>{farmer?.content}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </FadeUp>
  );
};

export default AgriEmpower;
