import Banner from "containers/AboutUs/Banner";
import { AgricultureFarming } from "containers/Agriculture/AgricultureFarming";
// import AgriIncome from "containers/Agriculture/AgriIncome";

import "containers/Agriculture/agriculture.scss";

import bgImage from "assets/images/agriculture/bgImage.png";

const Agriculture = () => {
  const title = "Our Solutions for Agri Ecosystem";

  return (
    <section className="agri">
      <Banner title={title} bgImage={bgImage} />
      <AgricultureFarming />
      {/* <AgriIncome /> */}
    </section>
  );
};

export default Agriculture;
