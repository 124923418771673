// images
// import animalHealth from "assets/images/dairyFarming/animalHealth.png";
import animalNutrition from "assets/images/dairyFarming/animalNutrition.png";
import Veterinary from "assets/images/dairyFarming/veterinary.png";
import uzhavaralayam from "assets/images/dairyFarming/uzhavaralayam.png";
import IITMadras from "assets/images/dairyFarming/IITMadras.png";
import foodPartner from "assets/images/dairyFarming/mabiFoundation.png";

export const domainPartners = [
  {
    id: 1,
    title: "Our Strong Network of Domain Partners",
    image: animalNutrition,
    para: "ICAR - National Dairy Research Institute(NDRI), Southern Regional Station, Bengaluru",
    role: "Domain partner – Animal Nutrition"
  },
  {
    id: 2,
    title: "Our Strong Network of Domain Partners",
    image: Veterinary,
    para: "The University of Trans Disciplinary Health Science & Technology",
    role: "Domain partner – Ethno-Veterinary Practices"
  },
  {
    id: 3,
    title: "Our Strong Network of Domain Partners",
    image: foodPartner,
    para: "Madurai Agribusiness Incubation Forum, TNAU Madurai Campus",
    role: "Domain partner – Food Processing"
  },
  {
    id: 4,
    title: "Our Strong Network of Domain Partners",
    image: IITMadras,
    para: "Indian Institute of Technology Madras",
    role: "Technology Partner"
  },
  {
    id: 5,
    title: "Our Strong Network of Domain Partners",
    image: uzhavaralayam,
    para: "Uzhavaralayam Farmer Producers Company Ltd.",
    role: "Implementation Partner ​"
  }
];
