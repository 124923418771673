import { Link } from "react-router-dom";
import FadeUp from "components/FadeUp/index";

const CareersInfo = () => {
  const info = {
    heading: "Join us at Bharat Mandi",
    description:
      "Join us at Bharat Mandi and be a part of a team dedicated to making a difference in the lives of smallholder farmers and the sustainability of agribusiness. Together, we can drive positive change and create a better future for all.\n\nDo you have any difficulty finding a job? Send us your resume and portfolio to"
  };

  const { heading, description } = info;
  return (
    <section className="careerInfo">
      <div className="container">
        <div className="careerInfoPage">
          <FadeUp>
            <h2>{heading}</h2>
            <p>{description}</p>
            <button>
              <Link
                to="mailto:Info@bharatmandi.com"
                target={"_blank"}
                rel="noreferrer"
              >
                Info@bharatmandi.com
              </Link>
            </button>
          </FadeUp>
        </div>
      </div>
    </section>
  );
};

export default CareersInfo;
