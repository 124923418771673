import entrepreneur from "assets/images/blogPage/entrepreneur.png";
import farmers from "assets/images/blogPage/entrepreneurFarmer.png";
import animals from "assets/images/blogPage/animals.png";

import brandLogo from "assets/images/blogPage/brandLogo.png";

export const blogNews = [
  {
    id: 1,
    image: entrepreneur,
    description: "Can we improve farmer income while preserving nature?",
    brandLogo: brandLogo
  },
  {
    id: 2,
    image: farmers,
    description: "Can we improve farmer income while preserving nature?",
    brandLogo: brandLogo
  },
  {
    id: 3,
    image: animals,
    description: "Can we improve farmer income while preserving nature?",
    brandLogo: brandLogo
  }
];
