import { useNavigate } from "react-router-dom";

import useScreenMobile from "hooks/useScreenMobile";
import { blogProfile } from "constant/blogPage";

import "containers/BlogPage/blogPage.scss";

import rotateImg from "assets/images/blogPage/rotate.svg";
import FadeUp from "components/FadeUp/index";

const Banner = () => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/blogs/${7}`);
  };

  const isMobile = useScreenMobile({ size: 768 });

  return (
    <>
      <FadeUp>
        <section
          className="blogBanner"
          style={{
            backgroundImage: `url(${
              isMobile ? blogProfile[6].mobileImage : blogProfile[6].image
            })`
          }}
        >
          <div className="container blogBannerNewContainer">
            <div className="blogBannerContent">
              <div className="blogBannerHeader">
                <span>Latest blogs</span>
                <h1>Can we improve farmer income while preserving nature?</h1>
                <p>
                  Except for those living under a rock, the last year has been a
                  crash course in “virus”, “microbe”,Except for those living
                  under a rock, the last year...
                </p>
                <div className="blogBannerAuthor">
                  <div className="blogBannerAuthorDetails">
                    <div>
                      <h4>Veronika Belova</h4>
                      <p>May 23 2023</p>
                    </div>
                  </div>
                </div>
                <div className="blogBannerReload">
                  <img src={rotateImg} alt="reloadImg" />
                  <p>4 mins read</p>
                </div>
                <button onClick={handleCardClick}>Read Blog</button>
              </div>
            </div>
          </div>
        </section>
      </FadeUp>
    </>
  );
};

export default Banner;
