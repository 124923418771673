// images
import mapMyCrop from "assets/agriFarmers/mapMyCrop.png";
import domainPartners from "assets/agriFarmers/domainPartners.png";
import FadeUp from "components/FadeUp/index";

const DomainPartners = () => {
  return (
    <FadeUp>
      <section className="partners">
        <div className="container">
          <h2 className="agriGrowthHeading">
            Our Strong Network of Domain Partners
          </h2>

          <div className="partnersNetwork">
            <div className="partnersLogo">
              <img src={mapMyCrop} alt="mapMyCrop" />
              <p>Satellite based Farm Monitoring</p>
            </div>
            <div className="partnersLogo">
              <img
                src={domainPartners}
                alt="domainPartners"
                className="domainLogo"
              />
              <p>University of Agricultural Sciences, Bangalore</p>
            </div>
          </div>
        </div>
      </section>
    </FadeUp>
  );
};

export default DomainPartners;
