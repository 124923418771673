// src/constants/privacyPolicyContent.js

export const privacyPolicyContent = [
  {
    name: "Introduction",
    description: "This Privacy Policy describes how your personal information is collected, used, and shared when you visit, subscribe, register, or make a purchase from http://bharatmandi.com, ‘Site’."
  },
  {
    name: "Personal Information We Collect",
    description: "When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.” We collect Device Information using the following technologies:",
    subSections: [
      {
        name: "Log Files",
        description: "“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps."
      },
      {
        name: "Web Beacons, Tags, and Pixels",
        description: "“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site."
      },
      {
        name: "Google Analytics",
        description: "“Google Analytics”, “events” and “pixels” that records traffic-related information and how you interact with the site."
      }
    ]
  },
  {
    name: "Order Information",
    description: "Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, PayPal email, bank details), email address, and phone number. We refer to this information as “Order Information.” When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information."
  },
  {
    name: "How We Use Your Personal Information",
    description: "We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to communicate with you; screen our orders for potential risk or fraud; and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services. We mainly use the Order Information for invoicing purposes. We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns)."
  },
  {
    name: "Sharing Your Personal Information",
    description: "We share your Personal Information with third parties to help us use your Personal Information, as described above. We also use Google Analytics to help us understand how our customers use the Site–you can read more about how Google uses your Personal Information [here](https://www.google.com/intl/en/policies/privacy/). You can also opt-out of Google Analytics [here](https://tools.google.com/dlpage/gaoptout). Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful requests for information we receive, or to otherwise protect our rights."
  },
  {
    name: "Behavioral Advertising",
    description: "As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at [this link](http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work). When you come to our site, third parties may place cookies on your browsers for targeted advertising purposes. You can opt out of targeted advertising by:"
  },
  {
    name: "Do Not Track",
    description: "Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser."
  },
  {
    name: "Your Rights",
    description: "If you are a European resident, you have the right to access the personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. Additionally, if you are a European resident, we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States."
  },
  {
    name: "Data Retention",
    description: "When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information."
  },
  {
    name: "Minors",
    description: "The Site is not intended for individuals under the age of 18."
  },
  {
    name: "Changes",
    description: "We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons."
  },
  {
    name: "GDPR and Bharatmandi",
    description: "Bharatmandi is implementing the GDPR principles to become GDPR compliant. General Data Protection Regulation (GDPR) of INDIA, is the latest privacy regulation and policy that will be implemented in May 2020 all over the world. Here at Bharatmandi, we are working strictly to bring transparency to our data uses – how we are going to collect, store and use our customers and users’ (your) data. Bharatmandi always takes good care of user data and maintains the privacy because it is crucially important to us. From the start of our company, we have maintained strict principles of data protection and privacy."
  },
  {
    name: "Usage of SMS Permissions",
    description: "Our app requests the following SMS permissions:",
    subSections: [
      {
        name: "Receive SMS (android.permission.RECEIVE_SMS)",
        description: "These permissions are used solely for the purpose of One-Time Password (OTP) verification for login purposes."
      },
      {
        name: "Read SMS (android.permission.READ_SMS)",
        description: "These permissions are used solely for the purpose of One-Time Password (OTP) verification for login purposes."
      }
    ]
  },
  {
    name: "Purpose of SMS Permissions",
    description: "OTP Verification: We use SMS permissions to automatically read the OTP sent to your device for verifying your phone number during the login process. This enhances your user experience by simplifying the verification process and reducing the need for manual entry."
  },
  {
    name: "Data Collection and Usage",
    description: "Data Collected: The OTP received via SMS is the only data collected using these permissions. Data Usage: The OTP is used exclusively for authenticating your login to our app. We do not store, share, or use the OTP or any other SMS content for any other purpose."
  },
  {
    name: "Data Security",
    description: "We implement appropriate security measures to protect your data and ensure that it is not accessed, disclosed, altered, or destroyed without authorization."
  },
  {
    name: "User Consent",
    description: "By using our app, you consent to our use of SMS permissions for the purposes described above. If you do not agree to this, you may choose to deny these permissions; however, this will disable the automatic OTP verification feature, and you will need to enter the OTP manually."
  },
  {
    name: "Permission Denial",
    description: "If you deny the SMS permissions, you will still be able to use our app, but you will need to manually enter the OTP sent to your phone number for verification during the login process."
  },
  {
    name: "Changes to This Policy",
    description: "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page."
  }
];
