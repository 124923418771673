import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const LoadCircle = ({ percent }) => {
  return (
    <div className="scroll-progress-bar">
      <CircularProgressbar
        value={percent}
        styles={buildStyles({
          pathTransitionDuration: 0.5,
          textColor: "green",
          trailColor: "#D8EDE2",
          backgroundColor: "red",
          pathColor: "#009A4A"
        })}
      />
    </div>
  );
};

export default LoadCircle;
