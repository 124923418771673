import ContactBangalore from "assets/images/contactUs/contactBangalore.png";

export const contactBangalore = [
  {
    id: 1,
    image: ContactBangalore,
    address:
      "549, 1st Floor, 7th Main, Rajiv Gandhi Nagar, 2nd Phase, NTI Layout, Vidyaranyapura Post, Bangalore - 560 097",
    heading: "Start a conversation",
    description:
      "Let us know your requirements and we'll get back to you as soon as possible",
    map: "https://goo.gl/maps/Wxv5YMAmybwasBmGA"
  }
];
