import Banner from "containers/AboutUs/Banner";
import DairyFarmLiveHood from "containers/DairyFarm/DairyFarmLiveHood";
import DairyFarmGrowth from "containers/DairyFarm/DairyFarmGrowth";
import DomainPartners from "containers/DairyFarm/DomainPartners";

// component
// import Carousel from "components/carousel/index";

import bgImage from "assets/images/dairyFarming/bgImage.png";
import mobileBgImage from "assets/images/dairyFarming/mobileImage.png";

const DairyFarm = () => {
  const title =
    "Bharat Mandi significantly enhances the livelihoods of women dairy farmers";
  return (
    <div className="agriFarmPage">
      <div className="dairyFarm">
        <Banner title={title} bgImage={bgImage} smBgImage={mobileBgImage} />
        <DairyFarmLiveHood />
        <DairyFarmGrowth />
        <DomainPartners />
        {/* TODO */}
        {/* <Carousel /> */}
      </div>
    </div>
  );
};

export default DairyFarm;
