const Arrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_634_8)">
        <path
          d="M3.5 12L11.5 4"
          stroke="#009A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 4H11.5V10"
          stroke="#009A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_634_8">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-1 0 0 -1 16 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Arrow;
