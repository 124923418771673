// styles
import "containers/PrivacyPolicy/privacyPolicy.scss";
import { privacyPolicyContent } from "constant/privacyPolicyContent";
import FadeUp from "components/FadeUp/index";

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy">
      <div className="privacy-policy-container">
        <div className="privacy-policy-heading">
          <FadeUp>
            <h1>Privacy Policy</h1>
          </FadeUp>
        </div>
        <div className="privacy-policy-content">
          {privacyPolicyContent.map((section, index) => (
            <div key={index}>
              <h2>{section.name}</h2>
              <p>{section.description}</p>
              {section.subSections && (
                <div>
                  {section.subSections.map((subSection, subIndex) => (
                    <div key={subIndex}>
                      <h3 className="subsection">{subSection.name}</h3>
                      <p className="subsection-content">{subSection.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
