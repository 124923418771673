import dairyImg from "assets/images/techSolutions/consumers.png";

export const consumer = [
  {
    id: 1,
    name: "consumers",
    description: [
      "Consumers can buy traceable residue-free milk and dairy products  directly from Bharat Mandi app and through retail stores.",
      "Consumers can obtain information about the origin and quality of dairy products purchased by them, tracing them back to the individual farmer level. The system utilises unique identification codes on product packaging, enabling consumers to access data about the farmer's practices, the production process, and the health records of the cattle involved in the production.",
      "A portion of revenues received by Bharat Mandi goes towards upliftment of underserved women dairy farmers."
    ],
    image: dairyImg
  }
];
