import IntrapreneursImage1 from "assets/images/aboutUs/intrapreneursImage1.png";
import IntrapreneursImage2 from "assets/images/aboutUs/intrapreneursImage2.png";
import IntrapreneursImage3 from "assets/images/aboutUs/intrapreneursImage3.png";
import IntrapreneursImage4 from "assets/images/aboutUs/intrapreneursImage4.png";

export const intrapreneurs = [
  {
    id: 1,
    name: "Prakash Vivekanadan",
    title: "Head – Product ​ (Sales)",
    image: IntrapreneursImage1,
    description:
      "An IT Professional coming from a farming family with 18 years of experience in IT Security Infrastructure Management handling Enterprise Security. Co-founded FarmSys, an AgTech venture focused on pioneering soil analysis technologies to empower smallholder farmers",
    profile: "https://www.linkedin.com/in/prakashfarmsys/"
  },
  {
    id: 2,
    name: "Ramasamy Esvar",
    title: "Head – Research & Development",
    image: IntrapreneursImage2,
    description:
      "An accomplished Software Professional with 18+ years of expertise in designing and developing diverse Embedded products. Co-founded FarmSys, an AgTech venture focused on pioneering soil analysis technologies to empower smallholder farmers.",
    profile: "https://www.linkedin.com/in/ramasamy-esvar/"
  },
  {
    id: 3,
    name: "Vignesh M S",
    title: "Head – Products (Dairy)",
    image: IntrapreneursImage3,
    description:
      "Agri-tech entrepreneur experienced in building products from zero to one. Co-founded Statlogic, a dairy tech firm that developed AI-powered products for the stakeholders in dairy value chain.",
    profile: "https://www.linkedin.com/in/vigneshms2408/"
  },
  {
    id: 4,
    name: "Tarun Gupta",
    title: "Head – Products​ (Agriculture)​",
    image: IntrapreneursImage4,
    description:
      "Agri entrepreneur experienced in building product & supply chains across various industries like agriculture, e-commerce & FMCG. ​",
    profile: "https://www.linkedin.com/in/garuntupta/"
  }
];
