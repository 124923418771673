import ChallengesImage from "assets/images/aboutUs/challengesImage.png";
import FadeUp from "components/FadeUp/index";

const Challenges = () => {
  return (
    <>
      <section className="challenges">
        <div className="container">
          <div className="challengesImages">
            <FadeUp>
              <img src={ChallengesImage} alt="challengesImage" />
            </FadeUp>
          </div>
        </div>
      </section>
    </>
  );
};

export default Challenges;
