import dairyImg from "assets/images/agriculture/budget.png";

export const stakeHolder = [
  {
    id: 1,
    name: "financial Institutions",
    description: [
      "Secure funds transfer through closed-loop card/wallet.",
      "Algorithm estimates yield and income for each crop.",
      "Tranche-wise loan disbursement based on Package of Practices",
      "Buyback ensures prioritized loan repayment by farmers",
      "Dashboard for banks/NBFCs to track crop growth and risks.",
      "Farmer scorecard for adherence to practices.",
      "Location, farmer, and crop information for risk profiling."
    ],
    image: dairyImg
  }
];
