import Banner from "containers/ContactUs/Banner";
import Enquiry from "containers/ContactUs/Enquiry";
import Conversation from "containers/ContactUs/Conversation";

const index = () => {
  return (
    <>
      <Banner />
      <Conversation />
      <Enquiry />
    </>
  );
};

export default index;
