import { useState } from "react";

import { agriFCPs } from "constant/agriFPO";
import { Buyers } from "constant/buyer";
import { stakeHolder } from "constant/stakeHolder";
import FadeUp from "components/FadeUp/index";

import "containers/Agriculture/agriculture.scss";

export const AgricultureFarming = () => {
  const [teamTab, setTeamTab] = useState(0);
  const [members, setMembers] = useState(agriFCPs);

  const handleTabChange = (tabIndex) => {
    setTeamTab(tabIndex);

    // tabChange
    if (tabIndex === 0) {
      setMembers(agriFCPs);
    } else if (tabIndex === 1) {
      setMembers(stakeHolder);
    } else {
      setMembers(Buyers);
    }
  };

  return (
    <section className="agriFarm agriFarmBgColor">
      <div className="container">
        <FadeUp>
          <h2 className="agriFarmHeaderText">
            Solutions for Agri Stakeholders
          </h2>
        </FadeUp>
        <FadeUp>
          <ul className="agriFarmHeader">
            <li
              className={teamTab === 0 ? "active" : ""}
              onClick={() => handleTabChange(0)}
            >
              FPCs
            </li>
            <li
              className={teamTab === 1 ? "active" : ""}
              onClick={() => handleTabChange(1)}
            >
              Financial Institutions
            </li>
            <li
              className={teamTab === 2 ? "active" : ""}
              onClick={() => handleTabChange(2)}
            >
              Buyers
            </li>
          </ul>
        </FadeUp>
        <FadeUp>
          <div className="agriFarmHeaderTabContent">
            {members.map((member, id) => (
              <>
                <div key={id} className="agriFarmHeaderTabContentData">
                  <p>{member.name}</p>
                  <img
                    src={member.image}
                    className="agriFarmHeaderTabContentMobile"
                    alt="image"
                  />

                  <ul>
                    {member.description.map((para, index) => (
                      <li key={index}>{para}</li>
                    ))}
                  </ul>
                </div>
                <img
                  src={member.image}
                  className="agriFarmHeaderTabContentDesktop"
                  alt="image"
                />
              </>
            ))}
          </div>
        </FadeUp>
      </div>
    </section>
  );
};
