import FarmerLiveHood from "containers/AgriFarm/FarmerLiveHood";
import AgricultureGrowth from "containers/AgriFarm/AgricultureGrowth";
import AgriEmpower from "containers/AgriFarm/AgriEmpower";
import DomainPartners from "containers/AgriFarm/DomainPartners";
// import Carousel from "components/carousel/index";
import Banner from "containers/AboutUs/Banner";

import bgImage from "assets/images/aboutUs/bannerAgriFarm.png";
import mobileBgImage from "assets/agriFarmers/mobileImg.png";

// style
import "containers/AgriFarm/agriFarm.scss";

const AgriFarm = () => {
  const title =
    "Significantly increasing the income and cash flow of small and marginal farmers";

  return (
    <div className="agriFarmPage">
      <div className="titleContainer">
        <Banner title={title} bgImage={bgImage} smBgImage={mobileBgImage} />
      </div>
      <FarmerLiveHood />
      <AgricultureGrowth />
      <AgriEmpower />
      <DomainPartners />
      {/* <Carousel /> */}
    </div>
  );
};

export default AgriFarm;
