import Arrow from "assets/images/Arrow";
import ArrowForward from "assets/images/arrowForward.svg";
import { useNavigate } from "react-router-dom";

const Content = ({
  background,
  backgroundMob,
  title,
  iconImage,
  showIcon,
  content,
  onClick,
  page,
  dynamicClass = ""
}) => {
  const navigate = useNavigate();
  const onclickLearnMore = (page) => {
    navigate(`${page}`);
  };
  return (
    <>
      <div className="bannerBackground">
        <img
          src={background}
          alt="Solution for Agri"
          className="bannerDesktopImage"
        />
        <img
          src={backgroundMob}
          alt="Solution for Agri"
          className="bannerMobImage"
        />
      </div>
      <div className={`bannerContainer ${dynamicClass}`} onClick={onClick}>
        <div className="bannerContents">
          <h2>{title}</h2>
          {content && <p>{content}</p>}
          {showIcon && (
            <div className="bannerIconImage">
              <img src={iconImage} alt="iconImage" />
            </div>
          )}
          <button
            className="bannerButton"
            onClick={() => onclickLearnMore(page)}
          >
            <span>Learn More</span> <Arrow />
          </button>
        </div>
      </div>
      <div className="bannerSwipeContainer">
        <div className="bannerSwipe">
          <p>Swipe</p>
          <div>
            <img src={ArrowForward} alt="ArrowForward" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
