import { blogNews } from "constant/newsArticles";

import brandLogo from "assets/images/blogPage/brandLogo.png";
import DropDown from "constant/Dropdown";
import FadeUp from "components/FadeUp/index";

const NewsArticles = () => {
  return (
    <section className="blogNews">
      <FadeUp>
        <div className="container containerBackground">
          <h2 className="heading">News Articles</h2>

          <div className="blogPageArticles">
            {blogNews.map((data, index) => (
              <div className="blogPageCard" key={index}>
                <div className="blogPageImg">
                  <img src={data?.image} alt="blogImage" />
                </div>

                <div className="blogPageContents">
                  <p>{data.description}</p>
                  <div className="brandLogo">
                    <img src={brandLogo} alt="brandLogo" />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="blogPageViewMore">
            <p>View More</p>
            <DropDown />
          </div>
        </div>
      </FadeUp>
    </section>
  );
};

export default NewsArticles;
