import dairyImg from "assets/images/techSolutions/financialInstitution.png";

export const financialInstitution = [
  {
    id: 1,
    name: "financial Institutions",
    description: [
      "Bharat Mandi’s cattle lending platform provides financial institutions with a systematic and objective way to assess the creditworthiness of farmers through farmer credit score and animal health score.",
      "The platform collects data on farmers' daily operations to calculate the credit score and daily milk production, vet interventions, body condition score to calculate animal health score.",
      "This data-driven approach enables financial institutions to make informed lending and insurance decisions, reducing risks and improving the overall efficiency of their operations."
    ],
    image: dairyImg
  }
];
