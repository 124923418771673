export const linkedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7.35782 5.67975C7.3576 6.12502 7.1805 6.55197 6.86548 6.86667C6.55047 7.18137 6.12334 7.35804 5.67807 7.35782C5.2328 7.3576 4.80585 7.1805 4.49115 6.86548C4.17645 6.55047 3.99978 6.12334 4 5.67807C4.00022 5.2328 4.17732 4.80585 4.49233 4.49115C4.80735 4.17645 5.23447 3.99978 5.67975 4C6.12502 4.00022 6.55197 4.17732 6.86667 4.49233C7.18137 4.80735 7.35804 5.23447 7.35782 5.67975ZM7.40819 8.60105H4.05037V19.111H7.40819V8.60105ZM12.7135 8.60105H9.37251V19.111H12.68V13.5958C12.68 10.5234 16.6842 10.238 16.6842 13.5958V19.111H20V12.4541C20 7.27471 14.0735 7.46779 12.68 10.0113L12.7135 8.60105Z"
      fill="#254E39"
    />
  </svg>
);

export const instaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.6512 2.96094C12.4371 2.96094 12.2628 3.13522 12.2628 3.34938C12.2628 3.56353 12.4371 3.73781 12.6512 3.73781C12.8654 3.73781 13.0397 3.56356 13.0397 3.34938C13.0397 3.13519 12.8654 2.96094 12.6512 2.96094ZM8.00027 4.55672C6.10145 4.55672 4.55664 6.10153 4.55664 8.00034C4.55664 9.89916 6.10145 11.444 8.00027 11.444C9.89911 11.444 11.4439 9.89919 11.4439 8.00038C11.4439 6.10156 9.89911 4.55672 8.00027 4.55672Z"
      fill="#254E39"
    />
    <path
      d="M11.6138 0H4.38616C1.96762 0 0 1.96763 0 4.38619V11.6138C0 14.0324 1.96762 16 4.38616 16H11.6138C14.0324 16 16 14.0324 16 11.6138V4.38619C16 1.96763 14.0324 0 11.6138 0ZM8 12.3885C5.58016 12.3885 3.61153 10.4198 3.61153 8C3.61153 5.58016 5.58019 3.61156 8 3.61156C10.4198 3.61156 12.3885 5.58019 12.3885 8C12.3885 10.4198 10.4198 12.3885 8 12.3885ZM12.651 4.68237C11.9158 4.68237 11.3177 4.08425 11.3177 3.34906C11.3177 2.61387 11.9158 2.01572 12.651 2.01572C13.3862 2.01572 13.9843 2.61384 13.9843 3.34903C13.9843 4.08422 13.3862 4.68237 12.651 4.68237Z"
      fill="#254E39"
    />
  </svg>
);

export const faceBookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M14 14.3887H16.5L17.5 10.3887H14V8.38867C14 7.35867 14 6.38867 16 6.38867H17.5V3.02867C17.174 2.98567 15.943 2.88867 14.643 2.88867C11.928 2.88867 10 4.54567 10 7.58867V10.3887H7V14.3887H10V22.8887H14V14.3887Z"
      fill="#254E39"
    />
  </svg>
);
