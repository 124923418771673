import { ecoSystem } from "constant/ecoSystem";
import FadeUp from "components/FadeUp/index";

const DairyEcoSystem = () => {
  return (
    <section className="farming">
      <div className="container">
        <FadeUp>
          <h2 className="techSolutionHeading">
            Our Tech Solutions for the Dairy Ecosystem
          </h2>
        </FadeUp>

        <FadeUp>
          {ecoSystem.map((item) => (
            <div className="farmingApproach" key={item.id}>
              <div className="farmingApproachImage">
                <img src={item?.image} alt={item.name} />
              </div>

              <div className="farmingApproachCardName">
                <h5>{item?.name}</h5>

                <div className="farmingApproachContents">
                  <ul>
                    {item.description.map((para, index) => (
                      <li key={index}>{para}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </FadeUp>
      </div>
    </section>
  );
};

export default DairyEcoSystem;
