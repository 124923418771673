export const linkedInIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6666 0C22.3738 0 23.0521 0.280951 23.5522 0.781048C24.0523 1.28115 24.3333 1.95942 24.3333 2.66667V21.3333C24.3333 22.0406 24.0523 22.7189 23.5522 23.219C23.0521 23.719 22.3738 24 21.6666 24H2.99992C2.29267 24 1.6144 23.719 1.1143 23.219C0.614203 22.7189 0.333252 22.0406 0.333252 21.3333V2.66667C0.333252 1.95942 0.614203 1.28115 1.1143 0.781048C1.6144 0.280951 2.29267 0 2.99992 0H21.6666ZM20.9999 20.6667V13.6C20.9999 12.4472 20.542 11.3416 19.7268 10.5264C18.9117 9.71128 17.8061 9.25333 16.6533 9.25333C15.5199 9.25333 14.1999 9.94667 13.5599 10.9867V9.50667H9.83992V20.6667H13.5599V14.0933C13.5599 13.0667 14.3866 12.2267 15.4133 12.2267C15.9083 12.2267 16.3831 12.4233 16.7332 12.7734C17.0833 13.1235 17.2799 13.5983 17.2799 14.0933V20.6667H20.9999ZM5.50659 7.41333C6.10067 7.41333 6.67042 7.17733 7.09051 6.75725C7.51059 6.33717 7.74659 5.76742 7.74659 5.17333C7.74659 3.93333 6.74659 2.92 5.50659 2.92C4.90896 2.92 4.33582 3.1574 3.91324 3.57999C3.49066 4.00257 3.25325 4.57571 3.25325 5.17333C3.25325 6.41333 4.26659 7.41333 5.50659 7.41333ZM7.35992 20.6667V9.50667H3.66659V20.6667H7.35992Z"
      fill="#5F786B"
    />
  </svg>
);
