import { useState } from "react";
import { Link } from "react-router-dom";
import { contactBangalore } from "constant/contactBangalore";
import { contactChennai } from "constant/contactChennai";
import FadeUp from "components/FadeUp/index";

// images
import ChennaiIcon from "assets/images/contactUs/contactChennaiIcon.png";
import BangaloreIcon from "assets/images/contactUs/contactBangaloreIcon.png";
import Arrow from "assets/images/Arrow";

const Conversation = () => {
  const [mapTab, setMapTab] = useState(0);
  const [members, setMembers] = useState(contactChennai);

  const handleTabChange = (tabIndex) => {
    setMapTab(tabIndex);
    if (tabIndex === 0) {
      setMembers(contactChennai);
    } else {
      setMembers(contactBangalore);
    }
  };
  return (
    <>
      <section className="conversation">
        <div className="container">
          <FadeUp>
            {members.map((member) => (
              <div className="conversationTabs" key={member.id}>
                <FadeUp>
                  <div className="conversationTabsContents">
                    <ul className="conversationTeamHeading">
                      <li
                        className={mapTab === 0 ? "active" : ""}
                        onClick={() => handleTabChange(0)}
                      >
                        <img src={ChennaiIcon} alt="chennaiIcon" /> Chennai
                      </li>

                      <li
                        className={mapTab === 1 ? "active" : ""}
                        onClick={() => handleTabChange(1)}
                      >
                        <img src={BangaloreIcon} alt="bangaloreIcon" />{" "}
                        Bangalore
                      </li>
                    </ul>
                    <div className="conversationTabsMaps">
                      <img src={member.image} alt="contactChennai" />
                    </div>
                    <div className="conversationTabsAddress">
                      <p>{member.address}</p>
                    </div>

                    <div className="conversationTabsDirection">
                      <Link to={member.map} target={"_blank"} rel="noreferrer">
                        Get direction <Arrow />
                      </Link>
                    </div>
                  </div>
                </FadeUp>
                <FadeUp>
                  <div className="conversationTabsForm">
                    <div className="conversationTabsFormHeading">
                      <span>{member.heading}</span>
                      <p>{member.description}</p>
                    </div>

                    <form className="conversationContactForm">
                      <div className="conversationContactFormInput">
                        <div>
                          <label>
                            Full Name <span>*</span>
                          </label>
                          <input type="text" placeholder="Enter your name" />
                        </div>
                        <div>
                          <label>
                            Company Name
                          </label>
                          <input
                            type="text"
                            placeholder="Enter your Company Name"
                          />
                        </div>
                      </div>

                      <div className="conversationContactFormInput">
                        <div>
                          <label>
                            Email Id
                          </label>
                          <input
                            type="text"
                            placeholder="Enter your Email Id "
                          />
                        </div>
                        <div>
                          <label>
                            Phone Number <span>*</span>
                          </label>
                          <div className="phoneInput">
                            <input
                              type="number"
                              placeholder="Enter your Phone Number"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="conversationContactFormTextArea">
                        <div>
                          <label>Message</label>
                          <textarea
                            className="form-control"
                            placeholder="Please enter a message here"
                            rows="4"
                            cols="50"
                          ></textarea>
                        </div>
                        <p>
                          <span>*</span>Kindly mark the fields with this symbol
                          to proceed further
                        </p>
                      </div>
                      <div className="conversationContactFormButtons">
                        <button>Submit</button>
                      </div>
                    </form>
                  </div>
                </FadeUp>
              </div>
            ))}
          </FadeUp>
        </div>
      </section>
    </>
  );
};

export default Conversation;
