export const HeaderArrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7625 5.96574L8.44327 11.3008C8.37995 11.3642 8.31135 11.409 8.23747 11.4354C8.16359 11.4618 8.08443 11.475 8 11.475C7.91557 11.475 7.83641 11.4618 7.76253 11.4354C7.68865 11.409 7.62005 11.3642 7.55673 11.3008L2.22164 5.96574C2.07388 5.81798 2 5.63329 2 5.41165C2 5.19002 2.07916 5.00004 2.23747 4.84173C2.39578 4.68342 2.58047 4.60426 2.79156 4.60426C3.00264 4.60426 3.18734 4.68342 3.34565 4.84173L8 9.49608L12.6544 4.84173C12.8021 4.69397 12.9842 4.62009 13.2005 4.62009C13.4169 4.62009 13.6042 4.69925 13.7625 4.85756C13.9208 5.01587 14 5.20057 14 5.41165C14 5.62273 13.9208 5.80743 13.7625 5.96574Z"
      fill="#1C1B1F"
    />
  </svg>
);
