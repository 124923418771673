// images
import baseLining from "assets/agriFarmers/baselining.png";
import tree from "assets/agriFarmers/tree.png";
import drone from "assets/agriFarmers/drone.png";
import linkages from "assets/agriFarmers/linkages.png";

export const farmers = [
  {
    id: 1,
    image: baseLining,
    name: "Baselining",
    description:
      "Our trained agriculturists on ground in every taluk (Raitha Mitras) baseline the current soil conditions using our proprietary soil testing device - SoilSpectraTM. They also baseline the current income of the farmer and his family."
  },
  {
    id: 2,
    image: tree,
    name: "Significantly Income Enhancing Solution",
    description:
      "Farmer is offered a scientifically designed solution on what crops to grow and which high-yielding seeds be sown. The solution offered is backed by a minimum income guarantee."
  },
  {
    id: 3,
    image: drone,
    name: "Scientific \u0022Live\u0022 Package of Practices",
    description:
      "Best practices to maximise grade 'A' quality and yield of the produce with live interventions based on hyperlocal conditions.",
    content: "Use of agri-tech solutions to reduce labor."
  },
  {
    id: 4,
    image: linkages,
    name: "Traceability and Market Linkages",
    description:
      "Our Farmers' mobile app captures entire validated traceability from seed to farm gate. Currently sold directly to retailers eliminating middlemen.Organic certification under process to target export markets.",
    content: "Organic certification under process to target export markets."
  }
];
