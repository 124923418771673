import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { blogProfile } from "constant/blogPage";
import { BlogLoaderArrow } from "constant/loader";
import ProfileCards from "components/ProfileCards/index";

import twitter from "assets/images/blogPage/twitter.svg";
import instagram from "assets/images/blogPage/instagram.svg";
import linkedIn from "assets/images/blogPage/linkedIn.svg";
import youTube from "assets/images/blogPage/youTube.svg";

import "containers/BlogPage/blogPage.scss";
import LoadCircle from "./LoadCircle";

const BlogArticles = () => {
  const blogArticleRef = useRef();
  const [scrollPercent, setScrollPercent] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const offset = blogArticleRef.current.offsetTop - innerHeight;
      const scrollPercentage =
        ((scrollY - offset) * 100) / blogArticleRef.current.clientHeight;
      if (scrollPercentage < 0) {
        setScrollPercent(0);
      } else {
        const setScroll =
          ((scrollY - offset) * 100) / blogArticleRef.current.clientHeight;
        setScrollPercent(setScroll);
      }
      if (scrollPercentage > 100) {
        setScrollPercent(100);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { id } = useParams();
  const blogProfileData = blogProfile.find((blog) => blog.id.toString() === id);

  const relatedBlogs = blogProfile.filter(
    (blog) =>
      blog.authorTitle === blogProfileData?.authorTitle &&
      blog.id.toString() !== id
  );

  return (
    <section className="blogs">
      <div className="blogArticle">
        <h2>{blogProfileData?.description}</h2>

        <div className="blogPageAuthorDetails">
          <div>
            <h4>{blogProfileData?.authorTitle}</h4>
            <p>{blogProfileData?.workPeriod}</p>
          </div>
        </div>

        <div className="blogArticleImg">
          <img src={blogProfileData?.image} alt="blogAuthor" />
        </div>

        <div className="blogArticleDescription" ref={blogArticleRef}>
          <div>
            <p>
              Users should always be at the center of your design process. When
              users live in rural areas, getting to know them comes with its own
              challenges: logistical hurdles, time restrictions, resource
              limitations, and the list goes on. But this doesn’t mean you get a
              pass to neglect the process of learning their problems and needs.
            </p>
            <p>
              At Bharatmandi, we recently launched an initiative called UT Day,
              or Usability Testing Day, which allows us to get to know our users
              even when we are miles apart with each other. On top of that,
              here’s what makes UT Day more valuable: it gives everyone in the
              company, who due to distance and role may not always be able to
              get into the field, first hand experience ‘meeting’ with users.
            </p>

            <div className="blogArticleSubHeading">
              <h3>How does that work?</h3>
              <p>
                Essentially, UT Day lets us rapidly test and learn across
                multiple areas of our product in a single day. As it is
                conducted remotely, we are able to broadcast the session so that
                everyone in the company can be observer — for once, everyone can
                put on a designer’s hat and get the chance to understand what
                our users need, where they struggle, or what has been and can be
                helpful for them in our product and services.
              </p>
              <p>
                We recently sat down with our Design Researcher, Fiona Yasmine,
                as she spoke about her experience running our first couple of UT
                Day sessions. Our conversation with her will help paint you a
                clearer picture of what UT Day looks like at Bharatmandi and how
                this has helped us.
              </p>
              <p>
                Hi Fiona! Great job on hosting our first UT Day sessions! Please
                tell our readers what UT Day at Bharatmandi is like!
              </p>
              <p>
                Fiona: UT Day is Usability Testing Day. It’s a day when we
                evaluate a product or service by testing it on representative
                users. The objective is to quickly identify usability issues and
                get users’ reactions or feedback about our product. During the
                session, we ask users to complete a task. By observing them
                doing this, we can immediately see how users complete a task.
                Did they complete it swiftly, or did it look like they were
                struggling? Were they able to complete it at all? It allows us
                to note what works or what doesn’t for users in the design of
                the product that we currently have. And we do all this remotely!
              </p>

              <h3>How is this possible? </h3>
              <p>
                Our research team uses Lookback, a digital platform that allows
                researchers to conduct usability testing and interviews
                remotely.
              </p>
            </div>
          </div>

          <div className="blogArticleSocialMedia">
            <div className="blogArticleSocialMediaLogo">
              <p>Share</p>
              <img src={twitter} alt="twitter" />
              <img src={instagram} alt="twitter" />
              <img src={linkedIn} alt="linkedIn" />
              <img src={youTube} alt="youTube" />
            </div>
            <div className="blogArticleSocialMediaLoader">
              <LoadCircle percent={scrollPercent} />
              <BlogLoaderArrow />
            </div>
          </div>
        </div>
      </div>

      <div className="blogProfile">
        <div className="container">
          <h3>Related Blogs</h3>
          <div className="blogPageArticles">
            {relatedBlogs.slice(0, 3).map((data, index) => (
              <ProfileCards key={index} data={data} onClick={() => data?.id} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogArticles;
