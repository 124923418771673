import SubsidiariesLogo1 from "assets/images/aboutUs/subsidiariesImage1.svg";
import SubsidiariesLogo2 from "assets/images/aboutUs/subsidiariesImage2.svg";
import SubsidiariesLogo3 from "assets/images/aboutUs/subsidiariesImage3.svg";

import BrandTruth from "assets/images/aboutUs/brandTruth.svg";
import BrandSoilSpectra from "assets/images/aboutUs/brandSoilSpectra.svg";
import BrandPashuAadhaar from "assets/images/aboutUs/brandPashuAadhaar.svg";
import BrandSomaScan from "assets/images/aboutUs/brandSomaScan.svg";
import BrandMilkTrak from "assets/images/aboutUs/brandMilkTrak.svg";
import BrandGauMandi from "assets/images/aboutUs/brandGauMandi.svg";
// import BrandBluePlanet from "assets/images/aboutUs/brandBluePlanet.svg";

import { Link } from "react-router-dom";
import Arrow from "assets/images/Arrow";
import FadeUp from "components/FadeUp/index";

const Brands = () => {
  return (
    <>
      <section className="common Brands">
        <div className="container">
          <div className="commonHeading">
            <FadeUp>
              <h2>Our Subsidiaries & Brands</h2>
              <p>Providing End-to-End Solutions from Farm to Fork</p>
            </FadeUp>
          </div>
          <div className="commonTitle">
            <FadeUp>
              <h4>Our Subsidiaries</h4>
            </FadeUp>
          </div>
          <FadeUp>
            <div className="commonSubsidiariesContainer">
              <div className="commonSubsidiariesContainerCards">
                <div className="commonSubsidiariesContainerLogo">
                  <img src={SubsidiariesLogo1} alt="subSidiariesLogo1" />
                  <button>Farmer Education</button>
                </div>
                <p>Rurafin Gram Paathshala Private Limited</p>
                <Link
                  to="https://grampaathshala.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Explore the website <Arrow />
                </Link>
              </div>

              <div className="commonSubsidiariesContainerCards">
                <div className="commonSubsidiariesContainerLogo">
                  <img src={SubsidiariesLogo2} alt="subSidiariesLogo1" />
                  <button>Agri-tech</button>
                </div>
                <p>Infarmsys Technologies Private Limited</p>
                <Link
                  to="https://farmsys.co/index.html"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Explore the website <Arrow />
                </Link>
              </div>

              <div className="commonSubsidiariesContainerCards">
                <div className="commonSubsidiariesContainerLogo">
                  <img src={SubsidiariesLogo3} alt="subSidiariesLogo1" />
                  <button>Fin-tech</button>
                </div>
                <p>Rurafin Loan Mandi Fintech Private Limited</p>
              </div>
            </div>
          </FadeUp>

          <FadeUp>
            <div className="commonSubsidiariesContainerProposed">
              <div className="commonSubsidiariesContainerProposedCards">
                <div className="commonSubsidiariesContainerProposedContents">
                  <p>Proposed Section-8 Company</p>
                  <button>Non-profit</button>
                </div>
              </div>

              <div className="commonSubsidiariesContainerProposedCards">
                <div className="commonSubsidiariesContainerProposedContents">
                  <p>Proposed NBFC</p>
                  <button>Fin-tech</button>
                </div>
              </div>
            </div>
          </FadeUp>
          <FadeUp>
            <div className="commonTitle">
              <h4>Our Brands</h4>
            </div>

            <div className="commonBrands">
              <div className="commonBrandsCards">
                <div className="commonBrandsLogo">
                  <img src={BrandTruth} alt="BrandTruth" />
                </div>
                <span className="headingSpan">
                  The Trudh - <span>The only true milk</span>
                </span>
                <p>
                  FMCG dairy brand promoting traceable residue-free dairy
                  products, procured from smallholder women dairy producers
                </p>
              </div>

              <div className="commonBrandsCards">
                <div className="commonBrandsLogo">
                  <img src={BrandSoilSpectra} alt="BrandSoilSpectra" />
                </div>
                <span className="headingSpan">SoilSpectra</span>
                <p>
                  A portable in-situ soil sensor that measures soil nutrition
                  value in seconds and gives crop & fertigation recommendation
                  in all major vernacular languages.
                </p>
              </div>

              <div className="commonBrandsCards">
                <div className="commonBrandsLogo">
                  <img src={BrandPashuAadhaar} alt="BrandPashuAadhaar" />
                </div>
                <span className="headingSpan">Pashu Aadhaar</span>
                <p>
                  A cost-effective animal identification & disease traceability
                  platform that enables facial recognition for bovines to create
                  an Aadhar-like database for dairy cattle
                </p>
              </div>
            </div>
          </FadeUp>

          <FadeUp>
            <div className="commonBrands">
              <div className="commonBrandsCards">
                <div className="commonBrandsLogo">
                  <img src={BrandSomaScan} alt="BrandSomascan" />
                </div>
                <span className="headingSpan">SomaScan</span>
                <p>
                  Low-cost device for early diagnosis of subclinical mastitis in
                  dairy cattle and help to procure milk based on somatic cell
                  count.
                </p>
              </div>

              <div className="commonBrandsCards">
                <div className="commonBrandsLogo">
                  <img src={BrandMilkTrak} alt="BrandMilkTrak" />
                </div>
                <span className="headingSpan">Milk Trak</span>
                <p>
                  An advanced and user-friendly milk traceability platform
                  developed exclusively for consumers who prioritise
                  transparency and quality in the dairy products they consume.
                </p>
              </div>

              <div className="commonBrandsCards">
                <div className="commonBrandsLogo">
                  <img src={BrandGauMandi} alt="BrandGauMandi" />
                </div>
                <span className="headingSpan">Gau Mandi</span>
                <p>
                  Gau Mandi is India&apos;s first ethical livestock marketplace,
                  blending online trading with transparency, animal welfare, and
                  trader prosperity.
                </p>
              </div>
            </div>
          </FadeUp>

          {/* <FadeUp>
            <div className="commonBrandsCards">
              <div className="commonBrandsLogo singleCard">
                <img src={BrandBluePlanet} alt="BrandBluePlanet" />
              </div>
              <span className="headingSpan">Blue Planet</span>
              <p>
                Agri input product marketplace for enhanced crop productivity
                promoting good farming practices
              </p>
            </div>
          </FadeUp> */}
        </div>
      </section>
    </>
  );
};

export default Brands;
