// styles
import "containers/ContactUs/contactUs.scss";
import FadeUp from "components/FadeUp/index";

const Banner = () => {
  return (
    <section className="contactBanner">
      <div className="container contactBannerContainer">
        <div className="contactBannerHeading">
          <FadeUp>
            <h1>Contact Us</h1>
            <p>Reach Out for Advice and Support on Agricultural Matters</p>
          </FadeUp>
        </div>
      </div>
    </section>
  );
};

export default Banner;
