import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import FadeUp from "components/FadeUp/index";

import { founders } from "constant/founders";
import { intrapreneurs } from "constant/intrapreneurs";
import { advisory } from "constant/advisory";

import { linkedInIcon } from "constant/aboutUs";

const Team = () => {
  const [teamCardHeight, setTeamCardHeight] = useState();
  const teamCardRef = useRef(null);
  const [teamTab, setTeamTab] = useState(0);
  const [members, setMembers] = useState(founders);

  const handleTabChange = (tabIndex) => {
    setTeamTab(tabIndex);

    if (tabIndex === 0) {
      setMembers(founders);
    } else if (tabIndex === 1) {
      setMembers(intrapreneurs);
    } else {
      setMembers(advisory);
    }
  };

  // onPage Resize change check
  useEffect(() => {
    const handleResize = () => {
      if (teamCardRef?.current?.children[0]?.children[0]?.children[1]) {
        teamCardRef.current.children[0].children[0].children[1].style.minHeight =
          "unset";
        setTeamCardHeight(
          teamCardRef?.current?.children[0]?.children[0]?.children[1]
            ?.offsetHeight
        );
      }
    };
    setTimeout(handleResize, 50);
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [members.length]);

  return (
    <>
      <section className="common">
        <div className="container">
          <div className="commonHeading">
            <FadeUp>
              <h2>Our Team</h2>
            </FadeUp>
          </div>

          <div className="tab-buttons">
            <ul className="teamHeading">
              <li
                className={teamTab === 0 ? "active" : ""}
                onClick={() => handleTabChange(0)}
              >
                Founders
              </li>
              <li
                className={teamTab === 1 ? "active" : ""}
                onClick={() => handleTabChange(1)}
              >
                Intrapreneurs
              </li>
              <li
                className={teamTab === 2 ? "active" : ""}
                onClick={() => handleTabChange(2)}
              >
                Advisory Team
              </li>
            </ul>

            {teamTab === 0 ? (
              <div className="teamParagraph">
                <p>
                  A team of professional bankers and serial entrepreneurs with a
                  vision of enabling sustainable incomes to small farmers.
                </p>
              </div>
            ) : (
              ""
            )}

            {teamTab === 1 ? (
              <div className="teamParagraph">
                <p>Meet the Entrepreneurs who become Intrapreneurs with us</p>
              </div>
            ) : (
              ""
            )}
            {teamTab === 2 ? (
              <div className="teamParagraph">
                <p>Guiding our journey with Expertise and Wisdom</p>
              </div>
            ) : (
              ""
            )}

            <div
              className={`teamTabs ${
                teamTab === 1
                  ? "teamIntra"
                  : teamTab === 2
                  ? "teamAdvisory"
                  : ""
              }`}
              ref={teamCardRef}
            >
              {members.map((member) => (
                <div className="teamTabsContents" key={member.id}>
                  <FadeUp>
                    <div className="teamTabsImage">
                      <img
                        src={member.image}
                        alt={`FounderImage${member.id}`}
                      />
                    </div>
                    <div
                      className="teamTabsProfile"
                      style={{
                        minHeight: `${teamCardHeight}px`
                      }}
                    >
                      <div className="teamTabsLinks">
                        <h5>{member.name}</h5>
                        <span>{member.title}</span>
                      </div>
                      {member.profile && (
                        <Link
                          className="teamTabsIcon"
                          to={member.profile}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {" "}
                          {linkedInIcon()}
                        </Link>
                      )}
                    </div>
                    <p>{member.description}</p>
                  </FadeUp>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
