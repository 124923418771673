import offerImg from "assets/images/careers/offerImg.png";
import FadeUp from "components/FadeUp/index";

const CareerOffer = () => {
  const offer = {
    heading: "What We Offer",
    description:
      " At Bharat Mandi, we are dedicated to empowering our team members to tackle daily challenges creatively.We provide you with the autonomy to devise solutions that can revolutionise the way we approach our work.Our support system encourages you to think and act outside the box, enabling you to create a lasting impact - not only on our business but also on the lives of smallholder farmers and the environment."
  };

  const { heading, description } = offer;
  return (
    <section className="careerOffer">
      <div className="container">
        <FadeUp>
          <div className="careerOfferTeam">
            <div>
              <h3 className="commonHeading careerOfferHeader">{heading}</h3>
              <img
                src={offerImg}
                alt="offerImg"
                className="careerOfferTeamMobile"
              />
              <p>{description}</p>
            </div>

            <div>
              <img
                src={offerImg}
                alt="offerImg"
                className="careerOfferTeamDesktop"
              />
            </div>
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default CareerOffer;
