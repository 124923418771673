import { useNavigate } from "react-router-dom";

import { blogProfile } from "constant/blogPage";
import ProfileCards from "components/ProfileCards/index";

import DropDown from "constant/Dropdown";
import FadeUp from "components/FadeUp/index";

const BlogContainer = () => {
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/blogs/${id}`);
  };

  return (
    <section className="blogPage">
      <div className="container">
        <FadeUp>
          <div className="blogPageArticles">
            {blogProfile.map((data, index) => (
              <>
                {index !== blogProfile.length - 1 && (
                  <ProfileCards
                    key={index}
                    data={data}
                    onClick={handleCardClick}
                  />
                )}
              </>
            ))}
          </div>
          <div className="blogPageViewMore">
            <p>View More</p>
            <DropDown />
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default BlogContainer;
