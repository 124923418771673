import ContactChennai from "assets/images/contactUs/contactChennai.png";

export const contactChennai = [
  {
    id: 1,
    image: ContactChennai,
    address:
      "Rural Mandi Fintech (P) Ltd, The Reef, Ground Floor, Plot No 9F, 5th Cross Street, Sunrise Avenue, Neelankarai, Chennai - 600115",
    heading: "Start a conversation",
    description:
      "Let us know your requirements and we'll get back to you as soon as possible",
    map: "https://goo.gl/maps/ynXXZYwGpb5Agcow8"
  }
];
