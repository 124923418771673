import AdvisoryImage1 from "assets/images/aboutUs/advisoryImage1.png";
import AdvisoryImage2 from "assets/images/aboutUs/advisoryImage2.png";
import AdvisoryImage3 from "assets/images/aboutUs/advisoryImage3.png";
import AdvisoryImage4 from "assets/images/aboutUs/advisoryImage4.png";
import AdvisoryImage5 from "assets/images/aboutUs/advisoryImage5.png";

export const advisory = [
  {
    id: 1,
    name: "Ravi Shankar​",
    title: "Director & Advisor",
    image: AdvisoryImage1,
    description: "Specialist in Data Analytics and Agricultural Policy.",
    profile: "https://www.linkedin.com/in/ravi-shankar-70584b1/"
  },
  {
    id: 2,
    name: "Subbu Subramanian​",
    title: "Director & Advisor",
    image: AdvisoryImage2,
    description: "Specialist in Financial Advisory and Management Consulting.",
    profile: "https://www.linkedin.com/in/subbu610802/"
  },
  {
    id: 3,
    name: "Dr. Chidananda",
    title: "Advisor",
    image: AdvisoryImage3,
    description: "Specialist in Animal Husbandry & Dairy Industry."
  },
  {
    id: 4,
    name: "Dr. Gracy Paily",
    title: "Advisor​​",
    image: AdvisoryImage4,
    description: "Specialist in Agri-Marketing & Farming best practices."
  },
  {
    id: 5,
    name: "Mr. Pankaj Sharma",
    title: "Advisor​​",
    image: AdvisoryImage5,
    description:
      "Specialist in Enterprise Risk Management, Cost Optimization & Strategy Planning in Dairy Sector​",
    profile: "https://www.linkedin.com/in/pankaj-sharma-3078745/"
  }
];
