// image
import farmers from "assets/agriFarmers/farmersLiveHood.png";
import mobileImg from "assets/agriFarmers/farmersResponsive.png";
import FadeUp from "components/FadeUp/index";

// styles
import "containers/AgriFarm/agriFarm.scss";

const FarmerLiveHood = () => {
  return (
    <section className="agriFarmers">
      <div className="container">
        <div className="agriFarmersChart">
          <FadeUp>
            <h2 className="agriGrowthHeading">The BharatMandi Impact</h2>
            <label>Impact on Typical Small & Marginal Agri Farmer</label>
            <img
              src={farmers}
              alt="farmerImage"
              className="agriFarmersChartDesktop"
            />

            <img
              src={mobileImg}
              alt="mobileImg"
              className="agriFarmersChartResponsive"
            />
          </FadeUp>
        </div>
      </div>
    </section>
  );
};

export default FarmerLiveHood;
