export const BlogLoaderArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className="greenArrow"
  >
    <path
      d="M7.99844 15.9998C7.7151 15.9998 7.4776 15.904 7.28594 15.7123C7.09427 15.5206 6.99844 15.2831 6.99844 14.9998V3.8248L2.12344 8.69981C1.92344 8.89981 1.68594 8.9998 1.41094 8.9998C1.13594 8.9998 0.898438 8.89981 0.698438 8.69981C0.498438 8.49981 0.398438 8.26647 0.398438 7.9998C0.398438 7.73314 0.498438 7.4998 0.698438 7.2998L7.29844 0.699805C7.39844 0.599805 7.50677 0.528971 7.62344 0.487305C7.7401 0.445638 7.8651 0.424805 7.99844 0.424805C8.13177 0.424805 8.26094 0.445638 8.38594 0.487305C8.51094 0.528971 8.6151 0.599805 8.69844 0.699805L15.2984 7.2998C15.4984 7.4998 15.5984 7.73314 15.5984 7.9998C15.5984 8.26647 15.4984 8.49981 15.2984 8.69981C15.0984 8.89981 14.8609 8.9998 14.5859 8.9998C14.3109 8.9998 14.0734 8.89981 13.8734 8.69981L8.99844 3.8248V14.9998C8.99844 15.2831 8.9026 15.5206 8.71094 15.7123C8.51927 15.904 8.28177 15.9998 7.99844 15.9998Z"
      fill="#009A4A"
    />
  </svg>
);
