import { useEffect, useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { HeaderArrow } from "constant/headerArrow";

import "components/Header/header.scss";

import BharatMandi from "assets/images/bharatMandi.svg";
import Menu from "assets/images/menu.svg";
import Close from "assets/images/close.svg";

const Header = ({ setHamBurger, hamBurger }) => {
  const [impact, setImpact] = useState(true);
  const [solution, setSolution] = useState(true);
  const [position, setPosition] = useState(0);
  const [hideHeader, setHideHeader] = useState(true);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  const dropdownContainerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current.contains(e.target)
      ) {
        setImpact(true);
        setSolution(true);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // document.body.style.overflow = hamBurger ? "auto" : "hidden";
  }, [hamBurger]);

  useEffect(() => {
    function setContainerHeight() {
      const newHeaderHeight = hamBurger
        ? window.innerHeight - 56
        : window.innerHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${newHeaderHeight}px`
      );
      setHeaderHeight(`${newHeaderHeight}px`);
    }

    window.addEventListener("resize", setContainerHeight);
    setContainerHeight();

    return () => {
      window.removeEventListener("resize", setContainerHeight);
    };
  }, [hamBurger]);

  useEffect(() => {
    const handleScroll = () => {
      const moving = window.pageYOffset;
      if (moving > 100) {
        setHideHeader(position > moving);
      } else {
        setHideHeader(true);
      }
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const cls = hideHeader ? "" : "hidden";

  const handleClick = () => {
    setHamBurger(windowWidth <= 992 && !hamBurger);
  };

  const onImpact = () => {
    setSolution(true);
    setImpact(!impact);
  };

  const onSolution = () => {
    setSolution(!solution);
    setImpact(true);
  };

  const onMouseLeave = () => {
    setImpact(true);
    setSolution(true);
  };

  const onNavLinkClick = () => {
    setHamBurger(windowWidth <= 992 && !hamBurger);
    setImpact(true);
    setSolution(true);
  };

  return (
    <header className={`header ${cls}`}>
      <div className="headerContainer" ref={dropdownContainerRef}>
        <div className="headerBharatMandi">
          <Link to={"/"}>
            <img
              src={BharatMandi}
              alt="bharatMandi"
              style={{
                display: windowWidth < 992 && hamBurger ? "none" : "block"
              }}
            />
          </Link>
        </div>

        <div
          className="headerLinks"
          style={{
            display: hamBurger ? "flex" : "",
            height: windowWidth < 992 && hamBurger ? headerHeight : 0
          }}
        >
          <div className="headerData">
            <NavLink
              to="/"
              style={{
                display: windowWidth < 992 && hamBurger ? "flex" : "none"
              }}
              onClick={onNavLinkClick}
            >
              Home
            </NavLink>

            <NavLink to="/about-us" onClick={onNavLinkClick}>
              About Us
            </NavLink>

            <div
              className="headerDropDownContainer"
              onMouseLeave={onMouseLeave}
            >
              <div className="headerDropDown" onClick={() => onImpact()}>
                Our Impact
                <div
                  style={{
                    transform: impact ? "" : "rotate(180deg)",
                    display: "flex",
                    transition: "all 0.2s ease"
                  }}
                >
                  <HeaderArrow />
                </div>
              </div>

              <div
                className="headerChildLink"
                onMouseLeave={onMouseLeave}
                style={{ display: impact ? "none" : "flex" }}
              >
                <NavLink to="/agri-farm" onClick={onNavLinkClick}>
                  Agri Farmers
                </NavLink>

                <NavLink to="/dairy-farm" onClick={onNavLinkClick}>
                  Dairy Farmers
                </NavLink>
              </div>
            </div>

            <div
              className="headerDropDownContainer"
              onMouseLeave={onMouseLeave}
            >
              <div
                className="headerDropDown"
                onClick={onSolution}
                // onMouseOver={onSolution}
              >
                Tech Solutions
                <div
                  style={{
                    transform: solution ? "" : "rotate(180deg)",
                    display: "flex",
                    transition: "all 0.2s ease"
                  }}
                >
                  <HeaderArrow />
                </div>
              </div>

              <div
                className="headerChildLink headerDropLink"
                onMouseLeave={onMouseLeave}
                style={{ display: solution ? "none" : "flex" }}
              >
                <NavLink to="/agriculture" onClick={onNavLinkClick}>
                  Agriculture
                </NavLink>

                <NavLink to="/live-stock-farming" onClick={onNavLinkClick}>
                  Dairy Farming
                </NavLink>
              </div>
            </div>

            <NavLink to="/products" onClick={onNavLinkClick}>
              Products
            </NavLink>

            <NavLink to="/careers" onClick={onNavLinkClick}>
              Join Us
            </NavLink>

            {/* TODO */}
            {/* <NavLink to="/blogs" onClick={onNavLinkClick}>
              Blogs
            </NavLink> */}
          </div>

          <NavLink
            to="/contact-us"
            className="contactActive contactLink"
            onClick={onNavLinkClick}
          >
            <button
              className="headerContactUs"
              style={{
                display: hamBurger ? "" : "flex"
              }}
            >
              Contact Us
            </button>
          </NavLink>
        </div>

        <NavLink
          to="/contact-us"
          className="contactActive contactLinkMobile"
          onClick={onNavLinkClick}
        >
          <button
            className="headerContactUs"
            style={{
              display: hamBurger ? "" : "flex"
            }}
          >
            Contact Us
          </button>
        </NavLink>

        <div className="headerMenu" onClick={handleClick}>
          {!hamBurger ? (
            <img src={Menu} alt="hamBurger" />
          ) : (
            <img src={Close} alt="close" />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
