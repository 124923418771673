import Policy from "containers/PrivacyPolicy/Policy";

const index = () => {
  return (
    <>
      <Policy />
    </>
  );
};

export default index;
