import dairyImg from "assets/images/agriculture/fpoImage.png";

export const agriFCPs = [
  {
    name: "FPCs",
    description: [
      "Assist in identifying, hiring, and training skilled resources.",
      "Negotiate best deals with input and output providers.",
      "Implement systems for inventory, yield, settlements, and scaling.",
      "Provide dashboards for daily monitoring and measurement.",
      "Facilitate access to low-cost funds for working capital.",
      "Support exploration of alternate income opportunities.",
      "Offer advisory and guidance across various products/services.",
      "Create an attractive ecosystem for farmer FPO benefits."
    ],
    image: dairyImg
  }
];
