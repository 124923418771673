import { domainPartners } from "constant/dairyFarmPartners";
import FadeUp from "components/FadeUp/index";

const DomainPartners = () => {
  return (
    <section className="partners">
      <div className="container">
        <FadeUp>
          <h2 className="commonHeading">
            Our Strong Network of Domain Partners
          </h2>

          <div className="partnersNetwork">
            {domainPartners.map((item, id) => (
              <div className="partnersLogo" key={id}>
                <img src={item.image} alt="image" />
                <h3>{item.para}</h3>
                <p>{item.role}</p>
              </div>
            ))}
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default DomainPartners;
