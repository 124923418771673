import Banner from "containers/BlogPage/Banner";
import BlogContainer from "containers/BlogPage/BlogContainer";
import NewsArticles from "containers/BlogPage/NewsArticles";

// style
import "containers/BlogPage/blogPage.scss";

const BlogPage = () => {
  return (
    <div className="blogs">
      <Banner />
      <BlogContainer />
      <NewsArticles />
    </div>
  );
};

export default BlogPage;
