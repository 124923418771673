import FadeUp from "components/FadeUp/index";

const AboutCareer = () => {
  const career = {
    heading: "Why Choose Bharat Mandi?",
    description:
      " The need for a transformation in agribusiness is evident, and our leadership is at the forefront of driving this change. We believe that our national reach and expertise, combined with our innovative spirit and entrepreneurial culture, position us as leaders in this vital transformation."
  };

  const { heading, description } = career;

  return (
    <section className="aboutCareer">
      <div className="container">
        <FadeUp>
          <h3 className="commonHeading">{heading}</h3>
          <p className="aboutCareerPara">{description}</p>
        </FadeUp>
      </div>
    </section>
  );
};

export default AboutCareer;
