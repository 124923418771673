const ProfileCards = ({ data, onClick }) => {
  return (
    <div className="blogPageCard" onClick={() => onClick(data?.id)}>
      <div className="blogPageImg">
        <img src={data?.image} alt="blogImage" />
      </div>
      <div className="blogPageContent">
        <p>{data?.description}</p>

        <div className="blogPageAuthor">
          <div className="blogPageAuthorDetail">
            <div>
              <h4>{data?.authorTitle}</h4>
              <p>{data?.workPeriod}</p>
            </div>
          </div>

          <div className="blogPageReload">
            <img src={data?.refreshImg} alt="reloadImg" />
            <p>{data?.refreshTime}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCards;
