import smileFaceFarmer from "assets/images/blogPage/smileFaceFarmer.png";
import cashPriceVillager from "assets/images/blogPage/farmersCashImage.png";
import villagerImage from "assets/images/blogPage/farmersImage.png";
import youngVillage from "assets/images/blogPage/riceProductionFarmer.png";
import domesticImage from "assets/images/blogPage/domesticImage.png";
import villagePeople from "assets/images/blogPage/domestic.png";

import bgImage from "assets/images/blogPage/youngFarmerImage.png";
import mobileImg from "assets/images/blogPage/mobileImg.png";

import reloadImg from "assets/images/blogPage/reload.svg";

export const blogProfile = [
  {
    id: 1,
    image: smileFaceFarmer,
    description: "Can we improve farmer income while preserving nature?",
    authorTitle: "Veronika Belova",
    workPeriod: "May 23 2023",
    refreshImg: reloadImg,
    refreshTime: "2 mins read"
  },
  {
    id: 2,
    image: cashPriceVillager,
    description: "Can we improve farmer income while preserving nature?",
    authorTitle: "Veronika Belova",
    workPeriod: "May 23 2023",
    refreshImg: reloadImg,
    refreshTime: "2 mins read"
  },
  {
    id: 3,
    image: villagerImage,
    description: "Can we improve farmer income while preserving nature?",
    authorTitle: "Veronika Belova",
    workPeriod: "May 23 2023",
    refreshImg: reloadImg,
    refreshTime: "2 mins read"
  },
  {
    id: 4,
    image: youngVillage,
    description: "Can we improve farmer income while preserving nature?",
    authorTitle: "Veronika Belova",
    workPeriod: "May 23 2023",
    refreshImg: reloadImg,
    refreshTime: "2 mins read"
  },
  {
    id: 5,
    image: domesticImage,
    description: "Can we improve farmer income while preserving nature?",
    authorTitle: "Veronika Belova",
    workPeriod: "May 23 2023",
    refreshImg: reloadImg,
    refreshTime: "2 mins read"
  },
  {
    id: 6,
    image: villagePeople,
    description: "Can we improve farmer income while preserving nature?",
    authorTitle: "Veronika Belova",
    workPeriod: "May 23 2023",
    refreshImg: reloadImg,
    refreshTime: "2 mins read"
  },
  {
    id: 7,
    image: bgImage,
    mobileImage: mobileImg,
    description: "Can we improve farmer income while preserving nature?",
    authorTitle: "Veronika Belova",
    workPeriod: "May 23 2023",
    refreshImg: reloadImg,
    refreshTime: "2 mins read"
  }
];
