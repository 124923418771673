import { useState } from "react";

import { dairyFCPs } from "constant/dairy";
import { consumer } from "constant/consumer";
import { financialInstitution } from "constant/financialInstitution";
import FadeUp from "components/FadeUp/index";

import "containers/TechSolutions/techSolution.scss";

export const DairyFarming = () => {
  const [teamTab, setTeamTab] = useState(0);
  const [members, setMembers] = useState(dairyFCPs);

  const handleTabChange = (tabIndex) => {
    setTeamTab(tabIndex);

    // tabChange
    if (tabIndex === 0) {
      setMembers(dairyFCPs);
    } else if (tabIndex === 1) {
      setMembers(financialInstitution);
    } else {
      setMembers(consumer);
    }
  };

  return (
    <section className="farming farmingBgColor">
      <div className="container">
        <FadeUp>
          <h2 className="farmingHeaderText">
            Solutions for Dairy Stakeholders
          </h2>
        </FadeUp>
        <FadeUp>
          <ul className="farmingHeader">
            <li
              className={teamTab === 0 ? "active" : ""}
              onClick={() => handleTabChange(0)}
            >
              Dairy FPCs
            </li>
            <li
              className={teamTab === 1 ? "active" : ""}
              onClick={() => handleTabChange(1)}
            >
              Financial Institutions
            </li>
            <li
              className={teamTab === 2 ? "active" : ""}
              onClick={() => handleTabChange(2)}
            >
              Consumers
            </li>
          </ul>
        </FadeUp>
        <FadeUp>
          <div className="farmingHeaderTabContent">
            {members.map((member, id) => (
              <>
                <div key={id}>
                  <p>{member.name}</p>
                  <img
                    src={member.image}
                    className="farmingHeaderTabContentMobile"
                    alt="image"
                  />

                  <ul>
                    {member.description.map((para, index) => (
                      <li key={index}>{para}</li>
                    ))}
                  </ul>
                </div>
                <img
                  src={member.image}
                  className="farmingHeaderTabContentDesktop"
                  alt="image"
                />
              </>
            ))}
          </div>
        </FadeUp>
      </div>
    </section>
  );
};
