// styles
import "containers/Terms/terms.scss";
import { termsContent } from "constant/terms";
import FadeUp from "components/FadeUp/index";

const Terms = () => {
  return (
    <section className="terms">
      <div className="terms-container">
        <div className="terms-heading">
          <FadeUp>
            <h1>Terms & Conditions</h1>
          </FadeUp>
        </div>
        <div className="terms-content">
          {termsContent.map((section, index) => (
            <div key={index}>
              <h2>{section.name}</h2>
              <p>{section.description}</p>
              {section.subSections && (
                <div>
                  {section.subSections.map((subSection, subIndex) => (
                    <div key={subIndex}>
                      <h3 className="subsection">{subSection.name}</h3>
                      <p className="subsection-content">{subSection.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Terms;
