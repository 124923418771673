// image
import farmers from "assets/images/dairyFarming/liveHood.png";
import mobileImg from "assets/images/dairyFarming/liveHoodResponsive.png";
import FadeUp from "components/FadeUp/index";

// styles
import "containers/DairyFarm/dairyFarm.scss";

const DairyFarmLiveHood = () => {
  return (
    <section className="dairyFarming">
      <div className="container">
        <FadeUp>
          <h2 className="commonHeading">
            Impact on Women Dairy Farmers in Tamil Nadu
          </h2>
          <div className="dairyFarmingDesktop">
            <img src={farmers} alt="farmerImage" />
          </div>

          <div className="dairyFarmingResponsive">
            <img src={mobileImg} alt="mobileImg" />
            <p className="dairyFarmingResponsiveContent">
              In a span of 2 years, Chellammal&apos;s income will triple, and
              she will transition into becoming an independent dairy
              entrepreneur.
            </p>
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default DairyFarmLiveHood;
