import Banner from "components/Banner/Banner";

const Landing = ({ hamBurger }) => {
  return (
    <>
      <Banner hamBurger={hamBurger} />
    </>
  );
};

export default Landing;
