import dairyImg from "assets/images/techSolutions/dairyImg.png";

export const dairyFCPs = [
  {
    id: 1,
    name: "Dairy FPCs",
    description: [
      "Bharat Mandi helps farmer producer companies (FPCs) produce and procure traceable, residue-free milk from their farmers. It also connects FPCs with affordable green fodder sources.",
      "FPCs can sell their milk and dairy products in developed markets through Bharat Mandi, getting better prices and increasing their income.",
      "Bharat Mandi partners with animal research institutes to introduce advanced breeding technologies and ethno-veterinary medication for small dairy farmers, improving the quality of their cattle.",
      "Bharat Mandi provides educational content to farmers in local languages, teaching them best practices for animal care and dairy farming to enhance their profitability.",
      "Bharat Mandi help FPCs develop value-added dairy products"
    ],
    image: dairyImg
  }
];
