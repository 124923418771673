import { useEffect, useRef, useState } from "react";

import { agriFarmers } from "constant/agriFarmers";
import FadeUp from "components/FadeUp/index";

// styles
import "containers/AgriFarm/agriFarm.scss";

const AgricultureGrowth = () => {
  const [counter, setCounter] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    let interval;
    const handleScroll = () => {
      if (ref.current.getBoundingClientRect()?.top < 350) {
        interval = setInterval(() => {
          setCounter((prevCount) => prevCount + 1);
        }, 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //  function
  const counterDisplay = (index) => {
    switch (index) {
      case 0:
        return counter > 7000 ? "7000+" : counter;
      case 1:
        return counter > 22 ? "22+" : counter;
      case 2:
        return counter > 200 ? "200+" : counter;
      case 3:
        return counter > 10 ? "10+" : counter;
      default:
        return counter;
    }
  };

  return (
    <section className="agriGrowth" ref={ref}>
      <div className="container">
        <FadeUp>
          <h2 className="agriGrowthHeading">
            Bharat Mandi’s Impressive Growth Statistics Transforming Agriculture
          </h2>
        </FadeUp>
        <FadeUp>
          <div className="agriGrowthCardsWrap">
            {agriFarmers.map((dataItem, index) => (
              <div className="agriGrowthCard" key={index}>
                <span>{counterDisplay(index)}</span>
                <p>{dataItem?.description}</p>
              </div>
            ))}
          </div>
        </FadeUp>
      </div>
    </section>
  );
};

export default AgricultureGrowth;
