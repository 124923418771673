import dairyImg from "assets/images/agriculture/stakeHolder.png";

export const Buyers = [
  {
    id: 1,
    name: "Buyers",
    description: [
      "Dashboard showing Crop type, supply, quality, harvest calendar.",
      "Traceability for premium pricing.",
      "Contract farming for large-scale operations.",
      "Delivery to any location for cost reduction.",
      "Farmer engagement across crop cycles for loyalty and price stability."
    ],
    image: dairyImg
  }
];
