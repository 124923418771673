export const agriFarmers = [
  {
    value: "7000+",
    description:
      " Active Smallholder Farmers in the Eastern Dry Zone Region of Karnataka using Bharat Mandi recommended cropping solutions"
  },
  {
    value: "22+",
    description: "Horticulture Crops - Detailed Package of Practices Developed"
  },
  {
    value: "200+",
    description: "Tonnes Enriched Organic Manure Sold to Small Farmers"
  },
  {
    value: "10+",
    description: "Banks Working with us for offering loans to farmers"
  }
];
