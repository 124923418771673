const DropDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <mask
        id="mask0_3005_7832"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3005_7832)">
        <path
          d="M7.72727 12.8138C7.57868 12.8138 7.43938 12.7906 7.30937 12.7442C7.17936 12.6977 7.05863 12.6188 6.94719 12.5073L1.79313 7.35328C1.58883 7.14898 1.49132 6.8936 1.50061 6.58714C1.50989 6.28068 1.61669 6.0253 1.82099 5.82099C2.0253 5.61669 2.28532 5.51454 2.60107 5.51454C2.91681 5.51454 3.17684 5.61669 3.38114 5.82099L7.72727 10.1671L12.1013 5.79313C12.3056 5.58883 12.5609 5.49132 12.8674 5.50061C13.1739 5.50989 13.4292 5.61669 13.6335 5.82099C13.8378 6.0253 13.94 6.28532 13.94 6.60107C13.94 6.91681 13.8378 7.17684 13.6335 7.38114L8.50734 12.5073C8.3959 12.6188 8.27518 12.6977 8.14516 12.7442C8.01515 12.7906 7.87585 12.8138 7.72727 12.8138Z"
          fill="#052B17"
        />
      </g>
    </svg>
  );
};

export default DropDown;
