import Banner from "containers/AboutUs/Banner";
import AboutCareer from "containers/Careers/AboutCareer";
import CareerOffer from "containers/Careers/CareerOffer";
import CareerDifference from "containers/Careers/CareerDifference";
import CareersInfo from "containers/Careers/CareersInfo";
// import CareerJobs from "containers/Careers/CareerJobs";

import bgImage from "assets/images/careers/bgImage.png";
import mobileBgImage from "assets/images/careers/smBgImage.png";

import "containers/Careers/careers.scss";

const Careers = () => {
  const render = (
    <>
      <p className="aboutBannerDescription">
        At Bharat Mandi, we are on a mission to create a lasting impact on the
        lives of smallholder farmers and transform the agribusiness landscape
        sustainably. Our commitment to this cause is unwavering, and we are
        looking for passionate individuals to join our team.
      </p>
      <a href="#jobOpening" className="aboutBannerBtn">
        View Openings
      </a>
    </>
  );

  const title = "Careers at Bharat Mandi";

  return (
    <div className="career">
      <Banner
        title={title}
        bgImage={bgImage}
        renderContent={render}
        smBgImage={mobileBgImage}
      />
      <AboutCareer />
      <CareerOffer />
      <CareerDifference />
      {/* <CareerJobs /> */}
      <CareersInfo />
    </div>
  );
};

export default Careers;
