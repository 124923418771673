import "components/Footer/footer.scss";

import FooterLogo from "assets/images/aboutUs/footerLogo.svg";

import { linkedIcon, faceBookIcon, instaIcon } from "constant/footer";
import { Link } from "react-router-dom";
import FadeUp from "components/FadeUp/index";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <FadeUp>
            <div className="footerContainer">
              <div className="footerLogos">
                <Link to={"/"}>
                  <img src={FooterLogo} alt="footerLogo" />
                </Link>
                <p>
                  Our vision is to make agriculture sustainable for small and
                  marginal farmers by significantly improving their income and
                  making India the food bowl of the world
                </p>
                <div className="footerSocialLinks">
                  <div className="footerSocialLinksSize footerSocialLinksLinkedIcon">
                    <Link
                      to={"https://www.linkedin.com/company/bharatmandi/about/"}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {linkedIcon()}
                    </Link>
                  </div>
                  <div className="footerSocialLinksSize footerSocialLinksInstaIcon">
                    <Link
                      to={"https://www.instagram.com/mybharatmandi/"}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {instaIcon()}
                    </Link>
                  </div>
                  <div className="footerSocialLinksSize footerSocialLinksFaceBookIcon">
                    <Link
                      to={"https://www.facebook.com/mybharatmandi/?"}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {faceBookIcon()}
                    </Link>
                  </div>
                </div>
              </div>

              <div className="footerNavTab">
                <ul>
                  <a href="/">Home</a>
                </ul>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/careers">Join Us</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>

                {/* TODO */}
                {/* <li>
                  <a href="/blogs">Blogs</a>
                </li> */}
              </div>

              <div className="footerNavLinks">
                <ul>Our Impact</ul>
                <li>
                  <a href="/agri-farm">Agri Farmers</a>
                </li>
                <li>
                  <a href="/dairy-farm">Dairy Farmers</a>
                </li>
              </div>

              <div className="footerNavLinks">
                <ul>Tech solutions</ul>
                <li>
                  <a href="/agriculture">Agriculture</a>
                </li>
                <li>
                  <a href="/live-stock-farming">Dairy Farming</a>
                </li>
              </div>
            </div>

            <div className="footerStrips">
              <div className="footerCopy">
                <p>©2023 Rural Mandi Fintech Private Ltd. All rights reserved </p>
              </div>

              <div className="footerPolicy">
                <Link to='/privacy-policy'>
                  <span>Privacy & Policy</span>
                </Link>
                <Link to='/terms'>
                  <p>Terms & Condition</p>
                </Link>
              </div>
            </div>
          </FadeUp>
        </div>
      </footer>
    </>
  );
};

export default Footer;
