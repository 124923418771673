import "containers/AboutUs/aboutUs.scss";
import useScreenMobile from "hooks/useScreenMobile";
import FadeUp from "components/FadeUp/index";

const Banner = ({ title, bgImage, renderContent, smBgImage = bgImage }) => {
  const isMobile = useScreenMobile({ size: 768 });

  return (
    <>
      <section
        className="aboutBanner"
        style={{
          backgroundImage: `url(${isMobile ? smBgImage : bgImage})`
        }}
      >
        <div className="container aboutBannerNewContainer">
          <div className="aboutBannerContent">
            <div className="aboutBannerHeading">
              <div>
                <FadeUp>
                  <h1>{title}</h1>
                  <span>{renderContent}</span>
                </FadeUp>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
