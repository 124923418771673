import Banner from "containers/AboutUs/Banner";
import { DairyFarming } from "containers/TechSolutions/DairyFarming";
import DairyEcoSystem from "containers/TechSolutions/DairyEcoSystem";
// import FarmersIncome from "containers/TechSolutions/FarmersIncome";

import bgImage from "assets/images/techSolutions/bgImage.png";
import mobileBgImage from "assets/images/techSolutions/mobileImage.png";

const TechSolutions = () => {
  const title = "Our Solution for Dairy Ecosystem";
  return (
    <section className="diary">
      <Banner title={title} bgImage={bgImage} smBgImage={mobileBgImage} />
      <DairyFarming />
      <DairyEcoSystem />
      {/* <FarmersIncome /> */}
    </section>
  );
};

export default TechSolutions;
