import React, { useState } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "components/Banner/banner.scss";
import SolutionForAgriMobi from "assets/images/solutionForAgriMobi.png";
import SolutionForCattleMob from "assets/images/solutionForCattleMob.png";
import OfferingForAgriMob from "assets/images/offeringForAgriMob.png";
import OfferingFoCattleMob from "assets/images/offeringForCattleMob.png";
import SolutionForAgri from "assets/images/solutionForAgri.png";
import SolutionForCattle from "assets/images/solutionForCattle.png";
import OfferingForAgri from "assets/images/offeringForAgri.png";
import OfferingFoCattle from "assets/images/offeringForCattle.png";

import SolutionNewImage1 from "assets/images/solutionNewImage.png";
import SolutionForCattleImage from "assets/images/solutionForCattleImage.png";

import FadeUp from "components/FadeUp/index";

import Content from "components/Banner/Content";

const Banner = ({ hamBurger }) => {
  const [titles] = useState({
    SolutionForAgri:
      "Bharat Mandi significantly improves the livelihoods of small and marginal farmers",
    SolutionForCattle:
      "Bharat Mandi significantly enhances the livelihoods of landless and marginal women dairy farmers",
    successStoryAgri: "Success Stories - Agriculture",
    content:
      "“Bharat Mandi” is the brand name of the holding company of several integrated social impact companies.",
    successStoryLiveStock: "Success Stories - Livestock Farming",
    newcontent:
      "“Bharat Mandi” is the brand name of the holding company of several integrated social impact companies."
  });

  const showIcon = true;

  return (
    <FadeUp>
      <section className="banner">
        <Carousel
          autoPlay={true}
          swipeable={window.screen.availWidth <= 992 ? true : false}
          showArrows={false}
          transitionTime={500}
          verticalSwipe={false}
          stopOnHover={false}
          emulateTouch
          interval={4500}
          swipeScrollTolerance={50}
          infiniteLoop={true}
          showStatus
          animationHandler={window.screen.availWidth >= 992 ? "fade" : ""}
          showThumbs={false}
          preventMovementUntilSwipeScrollTolerance={true}
          statusFormatter={(currentItem, total) => {
            return (
              <span>
                <span className="currentItem"> {currentItem}</span>/{total}
              </span>
            );
          }}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const text = [
              { h: "Impact on Agri Farmers" },
              { h: "Impact on Dairy Farmers" },
              { h: "Success Stories - Agriculture" },
              { h: "Success Stories - Livestock Farming" }
            ];

            return (
              <>
                <div
                  className="progress"
                  style={{
                    display:
                      hamBurger && isSelected && window.screen.availWidth <= 768
                        ? "block"
                        : "none"
                  }}
                >
                  <div className="progress-value"></div>
                </div>
                <div
                  className="bannerTabsContent"
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    className="progress"
                    style={{ display: isSelected ? "block" : "none" }}
                  >
                    <div className="progress-value"></div>
                  </div>
                  <div>
                    <h5 style={{ color: isSelected ? "#FFFFFF" : "" }}>
                      {text[index].h}
                    </h5>
                    <p style={{ color: isSelected ? "#A3A3A3" : "" }}>
                      {text[index].p}
                    </p>
                  </div>
                </div>
              </>
            );
          }}
        >
          <Content
            onClick={() => {}}
            background={SolutionForAgri}
            backgroundMob={SolutionForAgriMobi}
            title={titles.SolutionForAgri}
            iconImage={SolutionNewImage1}
            showIcon={showIcon}
            page="/agri-farm"
          />
          <Content
            background={SolutionForCattle}
            backgroundMob={SolutionForCattleMob}
            title={titles.SolutionForCattle}
            iconImage={SolutionForCattleImage}
            showIcon={showIcon}
            dynamicClass="dairy"
            page="/dairy-farm"
          />
          <Content
            background={OfferingForAgri}
            backgroundMob={OfferingForAgriMob}
            title={titles.successStoryAgri}
            content={titles.content}
            dynamicClass="agriSuccess"
            page="/agriculture"
          />
          <Content
            background={OfferingFoCattle}
            backgroundMob={OfferingFoCattleMob}
            title={titles.successStoryLiveStock}
            content={titles.content}
            dynamicClass="agriSuccess"
            page="/live-stock-farming"
          />
        </Carousel>
      </section>
    </FadeUp>
  );
};

export default Banner;
