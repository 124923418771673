import "containers/Products/products.scss";

import C6maxProduct from "assets/images/products/c6max-product.png";
import CarbonlitesProduct from "assets/images/products/carbonlites-product.png";
import SoilSpectraProduct from "assets/images/products/soil spectra-product.png";

import FadeUp from "components/FadeUp/index";
import React, { useState, useEffect } from "react";

import Arrow from "assets/images/Arrow";

const OurProduct = () => {
  const [formState, setFormState] = useState({
    ProductName: "C6 Max",
    CustomerName: "",
    PhoneNumber: "",
    latitude: "",
    longitude: "",
    Address: "",
    State: "",
    Country: "",
    Date: new Date().toISOString().split("T")[0],
    submitted: false
  });

  const updateDate = () => {
    setFormState((prevState) => ({
      ...prevState,
      Date: new Date().toISOString()
    }));
  };

  useEffect(() => {
    updateDate();
    // Get current location when the component mounts
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setFormState({
            ...formState,
            latitude: latitude,
            longitude: longitude
          });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []); // Run only once when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formState.CustomerName || !formState.PhoneNumber || !formState.ProductName) {
      alert("Please fill in the details before submitting.");
      return;
    }

    try {
      // Get address details from OpenStreetMap API
      const { latitude, longitude } = formState;
      const addressResponse = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const addressData = await addressResponse.json();

      const { display_name, address } = addressData; // eslint-disable-line camelcase
      const { state, country } = address;

      // Set address details in formState
      setFormState({
        ...formState,
        Address: display_name, // eslint-disable-line camelcase
        State: state,
        Country: country
      });

      // Call your custom API with form data
      const apiResponse = await fetch(
        "https://hook.eu2.make.com/vvbqtdy8xk59ex8i4q2jpev7iga3gc9q",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formState)
        }
      );

      console.log("API Response:", apiResponse);
      setFormState({
        ...formState,
        submitted: true,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const scrollToForm = (productName) => {
    const formSection = document.getElementById("productFormSection");
    formSection.scrollIntoView({ behavior: "smooth" });
  
    setFormState((prevFormState) => ({
      ...prevFormState,
      ProductName: productName,
      submitted: false,
    }));
  };

  return (
    <section className="common product">
      <div className="container">
        <div className="commonHeading">
          <FadeUp>
            <h2>Our Products</h2>
          </FadeUp>
        </div>
        <div className="productContainer">
          <div className="eachProduct">
            <img src={C6maxProduct} alt="C6maxProduct" />
            <div className="product-content">
              <div className="agri">
                <a className="agriInputs">Agri Inputs</a>
              </div>

              <div className="content">
                <p>
                  <span id="c6">C6</span> <span id="max">Max </span>
                  is an organic manure which helps to reduce reliance on
                  synthetic fertilizers, minimises soil degradation, promotes a
                  balanced and resilient soil ecosystem.
                </p>
                <br />
                <p>
                  It is a holistic, non-invasive solution with eco-friendly
                  approach to Soil and Crop Management, ensuring sustainable
                  agriculture for a greener future.
                </p>
              </div>
              <button className="btn btn-buynow" onClick={() => scrollToForm("C6 Max")}>
                Buy Now <Arrow />
              </button>
            </div>
          </div>

          <div className="eachProduct">
            <img src={CarbonlitesProduct} alt="CarbonlitesProduct" />
            <div className="product-content">
              <div className="agri">
                <a className="agriInputs">Agri Inputs</a>
              </div>
              <div className="content">
                <p>
                  <span id="carbon">Carbon</span>
                  <span id="lites">lites </span>
                  Organic Manure is IMO and FCO Certified and fully organic. It
                  has a balanced blend of macro and micro nutrients essential
                  for plant vitality.
                </p>
                <br />
                <p>
                  It combines nutrient-rich digestate with biomass and organic
                  materials.This enriched formula includes microbial consortia,
                  scientifically proven to enhance soil health and elevate yield
                  quality.
                </p>
              </div>
              <button className="btn btn-buynow" onClick={() => scrollToForm("Carbonlites")}>
                Buy Now <Arrow />
              </button>
            </div>
          </div>

          <div className="eachProduct">
            <img src={SoilSpectraProduct} alt="SoilSpectraProduct" />
            <div className="product-content">
              <div className="agri">
                <a className="agriInputs">Soil Testing</a>
              </div>
              <div className="content">
                <p>
                  <span id="soil">SOIL SPECTRA 3.0 </span>
                  helps you to unlock the full potential of your crops with data-driven efficiency.
                </p>
                <br />
                <p>
                  This cutting-edge tool provides an instant comprehensive analysis of crucial soil parameters along with a completely customized POP tailored to optimize your crop nutrition. 
                  Empowering you to optimize yields, promote sustainable growth, and streamline your cultivation costs.
                </p>
              </div>
              <button className="btn btn-buynow" onClick={() => scrollToForm("SOIL SPECTRA 3.0")}>
                Buy Now <Arrow />
              </button>
            </div>
          </div>
        </div>
        <div id="productFormSection" className="productForm">
          {formState.submitted ? (
            <div className="productFormHeading">
              <span>Form submitted successfully! We will get back to you as soon as possible</span>
            </div>
          ) : (
            <form className="conversationContactForm" onSubmit={handleSubmit}>
              <div className="productFormHeading">
                <span>Start a conversation</span>
                <p>Let us know your requirements and we will get back to you as soon as possible</p>
              </div>
              <div className="conversationContactFormInput">
                <div>
                  <label>
                    Your Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    name="CustomerName"
                    value={formState.CustomerName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>
                    Phone Number <span>*</span>
                  </label>
                  <div className="phoneInput">
                    <input
                      type="number"
                      placeholder="Enter your Phone Number"
                      name="PhoneNumber"
                      value={formState.PhoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label>
                    Select Product <span>*</span>
                  </label>
                  <div>
                    <select
                      name="ProductName"
                      value={formState.ProductName}
                      onChange={handleChange}
                    >
                      <option value="C6 Max">C6 Max</option>
                      <option value="Carbonlites">Carbonlites</option>
                      <option value="SOIL SPECTRA 3.0">SOIL SPECTRA 3.0</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="conversationContactFormTextArea">
                <p>
                  <span>*</span>Kindly mark the fields with this symbol to proceed
                  further
                </p>
              </div>
              <div className="conversationContactFormButtons">
                <button type="submit">Submit</button>
              </div>
            </form>
          )}
        </div>

        <div>{}</div>
      </div>
    </section>
  );
};

export default OurProduct;
