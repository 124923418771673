import CareerDiffImg from "assets/images/careers/careerDifference.png";
import CareerDiffImage from "assets/images/careers/careerDifferenceMobile.png";
import FadeUp from "components/FadeUp/index";

const CareerDifference = () => {
  const career = {
    heading: "Join Us in Making a Difference",
    description:
      "If you are driven by a passion for creating social impact and are eager to contribute to the livelihoods of smallholder farmers in a sustainable manner, we invite you to explore career opportunities at Bharat Mandi. Together, we can be the change-makers who drive positive transformations in the world of agribusiness."
  };

  const { heading, description } = career;

  return (
    <section className="careerDifference">
      <div className="container">
        <FadeUp>
          <h3 className="commonHeading">{heading}</h3>
          <p className="aboutCareerPara">{description}</p>

          <img
            src={CareerDiffImg}
            alt="careerDifference"
            className="careerDifferenceDesktop"
          />
          <img
            src={CareerDiffImage}
            alt="careerDifference"
            className="careerDifferenceMobile"
          />
        </FadeUp>
      </div>
    </section>
  );
};

export default CareerDifference;
